import Vue from "vue";
import Vuex from "vuex";
import getMutations from "./mutations";
import actions from "./actions";
import getInitialState from "./state";
Vue.use(Vuex);
export let store;

export default function(namespace = "eopoi", defaultValues = {}) {
  store = new Vuex.Store({
    state: getInitialState(namespace, defaultValues),
    mutations: getMutations(namespace),
    actions
  });
  return store;
}
