import Vue from "vue";

Vue.prototype.$mainBlue = "#50727C";
Vue.prototype.$mainYellow = "#E4CC37";
Vue.prototype.$mainRed = "#AF3E4D";
Vue.prototype.$mainGreen = "#97bd0f";
Vue.prototype.$mainDarkGreen = "#5D7114";
Vue.prototype.$mainGrey = "#7a7a7a";
Vue.prototype.$mainDarkGrey = "#444444";
Vue.prototype.$mainLightBlue = "#A8C1C7";
Vue.prototype.$mainBeige = "#e2d7af";
Vue.prototype.$mainLightGrey = "#0000000d";
Vue.prototype.$mainOpal = "#A8C1C7";
Vue.prototype.$mainMystic = "#E6EEEF";
Vue.prototype.$mainOpenGreen = "#64B968";
