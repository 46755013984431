import L from "leaflet";
import { LMap, LTileLayer, LPopup } from "vue2-leaflet";
import MapIcon from "@/components/MapIcon/MapIcon.vue";
import "./Map.sass";
import "leaflet/dist/leaflet.css";
import {
  apiString,
  checkStep,
  getColorForParkingLot
} from "@/helpers/detailViewHelper";

export default {
  name: "Map",
  props: {
    pois: {
      type: Array,
      required: true
    },
    settings: {
      type: Object,
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    maxMapHeight: {
      type: Number,
      default: 500
    },
    mapWidth: {
      type: Number
    },
    categoryGroups: {
      type: Array,
      default: []
    },
    categoryTypes: {
      type: Array,
      default: []
    },
    total: {
      type: Number
    }
  },
  data() {
    return {
      // url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      url: "https://osm.eberl-online.net/tile/{z}/{x}/{y}.png",
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
      positionOnPage: -1,
      isFullHeight: false,
      reduceHeightBy: 0, // calclulated on scroll when map is shown
      console
    };
  },
  components: {
    MapIcon,
    LMap,
    LTileLayer,
    LPopup
  },
  mounted() {
    this.positionOnPage =
      this.$refs.mapContainer?.getBoundingClientRect().y + window.scrollY;

    // disable zooming
    this.$refs.myMap?.mapObject.scrollWheelZoom.disable();
  },
  computed: {
    bounds() {
      const bounds = [];

      if (this.pois?.length) {
        this.pois.forEach(location => {
          // skip pois with empty coordinates
          if (!location.geo || !location.geo.lat || !location.geo.lng) return;

          bounds.push(L.latLng(location.geo.lat, location.geo.lng));
        });
      }
      if (!bounds || bounds.length === 0) return false;
      // set bounds of map to marker bounds
      return L.latLngBounds(bounds);
    },
    rolloverLocationId() {
      return (
        this.$store.state.rolloverMapLocationId ||
        this.$store.state.rolloverCardLocationId
      );
    },
    mapHeight() {
      return this.isFullHeight
        ? window.innerHeight - this.$store.state.mapAbove - this.reduceHeightBy
        : this.maxMapHeight;
    },
    calculatedMapWidth() {
      if (this.mapWidth) {
        return this.mapWidth + "px";
      }
      return "100%";
    },
    apiString
  },
  methods: {
    // render address once, only on demand...
    formatAddress(location) {
      if (
        location &&
        location.address &&
        this.settings &&
        this.settings.apiKey
      ) {
        let locationTitle = "";
        let hasCity = false;
        let address;

        if (location.address.address) {
          address = location.address.address;
        }

        if (this.settings.apiKey.displayLocationCity && location.address.city) {
          locationTitle += location.address.city;
          hasCity = true;
        }
        if (
          this.settings.apiKey.displayLocationDistrict &&
          location.address.district
        ) {
          locationTitle += (hasCity ? "-" : "") + location.address.district;
        }
        if (
          this.settings.apiKey.displayLocationCountry &&
          location.address.country
        ) {
          locationTitle += ", " + location.address.country;
        }

        if (locationTitle && address) return address + "<br/>" + locationTitle;
        if (!locationTitle && address) return address;
        return locationTitle;
      }
      return "";
    },
    href(location) {
      if (this.$store.state.targetUri) {
        // replace place holder strings with meaningful stuff
        return this.$store.state.targetUri.replace(
          /xxxSLUGxxx/,
          (location.slug || location._id) + this.apiString
        );
      }
      return "";
    },
    onMouseEnter(location) {
      // set store marker that we have entered location with id
      this.$store.commit("SET_ROLLOVER_MAP_LOCATION_ID", location._id);
    },
    onMouseLeave() {
      // clear store marker
      this.$store.commit("SET_ROLLOVER_MAP_LOCATION_ID", undefined);
    },
    onScroll() {
      const widgetTop = document.querySelector(".eopoi-widget").offsetTop;
      if (!this.isMobile) {
        if (this.positionOnPage >= widgetTop) {
          if (
            window.scrollY >=
            this.positionOnPage - this.$store.state.mapAbove
          ) {
            this.$refs.mapContainer.style.position = "fixed";
            this.$refs.mapContainer.style.top =
              this.$store.state.mapAbove + "px";
            this.isFullHeight = true;
            let endOfWidget = document.querySelector(
              "[namespace=" + this.$store.state.namespace + "] .end-of-widget"
            );
            if (!endOfWidget) {
              endOfWidget = document.querySelector(".end-of-widget");
              if (!endOfWidget) {
                console.error("can't find .end-of-widget");
              }
            }

            const maxFixedPos =
              (endOfWidget?.offsetTop || 0) - window.innerHeight;
            if (
              window.scrollY > maxFixedPos + widgetTop &&
              this.pois.length >= this.total
            ) {
              this.$refs.mapContainer.style.position = "absolute";
              this.$refs.mapContainer.style.top = maxFixedPos + "px";
              this.isFullHeight = false;
            }

            // reduce map height by a certain number if mapBelow is set
            if (this.$store.state.mapBelow > 0) {
              // calculate view port bottom
              const distanceToBottom =
                document.documentElement.scrollHeight -
                window.scrollY -
                window.innerHeight;

              // apply modifier
              this.reduceHeightBy =
                distanceToBottom < this.$store.state.mapBelow
                  ? this.$store.state.mapBelow - distanceToBottom
                  : 0;
            }

            this.$refs?.myMap?.mapObject?.invalidateSize();
          } else {
            this.$refs.mapContainer.style.position = "static";
            this.$refs.mapContainer.style.top = "";
            this.$refs.mapContainer.style.bottom = "";
            this.isFullHeight = false;
            this.$refs?.myMap?.mapObject?.invalidateSize();
          }
        }
      }
    },
    filterGeo() {
      if (this.pois?.length)
        return this.pois.filter(poi => !isNaN(poi?.geo?.lat));
    },
    mapLoaded() {
      // invalidate size - load map correctly in some T3 instances
      this.$refs?.myMap?.mapObject?.invalidateSize(false);
    },
    checkStep(step, poi) {
      return checkStep(step, poi, this.categoryGroups, this.categoryTypes);
    },
    getColorForParkingLot
  }
};
