import { checkStep } from "@/helpers/detailViewHelper";
import moment from "moment";
import "@/lib/colors";

export default {
  props: {
    poi: {
      type: Object,
      required: true
    }
  },
  computed: {
    categoryGroups() {
      return this.$store.state.categoryGroups;
    },
    categoryTypes() {
      return this.$store.state.categoryTypes;
    },
    isParkingLot() {
      return checkStep(
        "parkingLot",
        this.poi,
        this.categoryGroups,
        this.categoryTypes
      );
    },
    icon() {
      switch (this.poi.state.state) {
        case "open":
          if (this.isParkingLot) return "mdi-alpha-p-box";
          else return "mdi-checkbox-blank-circle";
        case "seasonEnd":
          return "mdi-calendar-remove";
        case "closed":
          if (this.isParkingLot) return "mdi-alpha-p-box";
          else return "mdi-minus-circle";
        case "openingHours":
          return "mdi-checkbox-blank-circle-outline";
        case "partiallyOpen":
          return "mdi-circle-half-full";
        case "avalancheLock":
          return "mdi-triangle";
        case "capacityExhausted":
        case "highWorkload":
          return "mdi-alpha-p-box";
      }
    },
    iconColor() {
      switch (this.poi.state.state) {
        case "capacityExhausted":
          return this.$mainYellow;
        case "partiallyOpen":
          return this.$mainYellow;
        case "closed":
          return this.$mainRed;
        case "highWorkload":
          return this.$mainRed;
        case "avalancheLock":
          return this.$mainRed;
        case "":
          return this.$mainBlue;
        default:
          return this.$mainOpenGreen;
      }
    },
    text() {
      switch (this.poi.state.state) {
        case "open":
          if (this.isParkingLot) return "Parkplatz offen";
          else return "Geöffnet";
        case "seasonEnd":
          return "Saison Ende";
        case "closed":
          if (this.isParkingLot) return "Parkplatz geschlossen";
          else return "Geschlossen / Nicht begehbar";
        case "openingHours":
          return "Gemäß Öffnungszeiten";
        case "partiallyOpen":
          return "Teilweise Geöffnet";
        case "avalancheLock":
          return "Gesperrt / Lawinensperrung";
        case "capacityExhausted":
          return "Aktuell belegt";
        case "highWorkload":
          return "Aktuell hohe Auslastung";
        /*default:
          return "Keine Angabe";*/
      }
    },
    opensIn() {
      const duration = this.poi.fuzzyOpenCloseTime
        ? moment.duration(Math.ceil(this.poi.opensIn / 86400), "d")
        : moment.duration(this.poi.opensIn, "s");

      return duration.locale("de").humanize(true);
    },
    closesIn() {
      // if fuzzy closing time or always open => skip
      if (
        this.poi.fuzzyOpenCloseTime ||
        (this.poi.openingHours && this.poi.openingHours.freeAccess)
      )
        return undefined;
      return moment
        .duration(this.poi.closesIn, "s")
        .locale("de")
        .humanize(true);
    }
  }
};
