import PictureMixin from "@/mixins/PictureMixin.vue";
import "./PoiPicture.sass";
import { checkStep } from "@/helpers/detailViewHelper";
import flatten from "lodash/flatten";
import { checkForImage, diffBox, difficulty } from "@/helpers/commonHelper";
import { opChain } from "@/mixins/globalMethods";

export default {
  name: "PoiPicture",
  props: {
    poi: {
      type: Object,
      required: true
    },
    useFallbackPicture: {
      type: Boolean,
      default: false
    },
    fallbackPicture: {
      type: Object
    },
    showCopyright: {
      type: Boolean,
      default: false
    },
    inCard: {
      type: Boolean,
      default: false
    },
    detailView: {
      type: Boolean,
      default: false
    },
    showCopyrightInList: {
      type: Boolean,
      default: false
    },
    settings: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    imageHasError: false,
    picLink: "",
    showFullCopyright: false,
    height: 360,
    imageHeight: 253.1,
    imageWidth: 450
  }),
  mixins: [PictureMixin],
  computed: {
    detailWebcam() {
      return this.detailView && this.picture.link;
    },
    pictures() {
      const pictures = this.poi ? this.camAndSeasonImages(this.poi) : [];
      pictures.forEach(pic => {
        if (!checkForImage(pic)) {
          this.picLink = pic.link || pic.url;
        }
      });
      return pictures.filter(pic => checkForImage(pic));
    },
    firstPicture() {
      return this.pictures && this.pictures.length && this.pictures?.[0];
    },
    picture() {
      // aspect either 16:9 on large width or 1:1 on smaller ones
      // const aspect = this.cols <= 4 ? '16:9' : '1:1'; // TODO ? makes sense?
      const aspect = "16:9";

      const pictures = this.pictures;

      if (
        (pictures && pictures.length && pictures[0]?.link) ||
        pictures[0]?.url
      ) {
        // return webcam-link incl. minute timestamp
        if (pictures?.[0]?.link) {
          return this.timeStampUrl(pictures?.[0]?.link);
        }
        // right image size?
        if (pictures?.[0]?.imageSizes && pictures?.[0].imageSizes?.[aspect]) {
          //remove comma in width/height f.e. ".75/" to "/"
          let fixedUrl = pictures[0].imageSizes[aspect].url.replace(
            /\.\d+\//g,
            "/"
          );
          if (this.detailView) {
            this.imageWidth = 1280;
            return fixedUrl.replace(
              "/focus/",
              "/mainimage/" + this.imageWidth + "/"
            );
          } else {
            return fixedUrl.replace(
              "/focus/",
              "/mainimage/" + this.imageWidth + "/"
            );
          }
        }
        const isOutdoorActiveImage = pictures[0].url?.includes("img.oastatic");
        if (this.detailView) {
          this.imageWidth = 1280;
          this.imageHeight = 720;
          if (isOutdoorActiveImage) {
            return pictures?.[0].url.replace(
              "/img/",
              `/img/${this.imageWidth}/${this.imageHeight}/`
            );
          }
          let smartCropUrl = pictures?.[0]?.url?.replace(
            "/pois/",
            "/smartcrop/" + this.imageWidth + "/" + this.imageHeight + "/pois/"
          );
          smartCropUrl = smartCropUrl.replace("https://", "");
          smartCropUrl = smartCropUrl.replace(/\/\//g, "/");
          return `https://${smartCropUrl}`;
        } else {
          if (isOutdoorActiveImage) {
            this.imageWidth = 600;
            this.imageHeight = 300;
            return pictures?.[0].url.replace(
              "/img/",
              `/img/${this.imageWidth}/${parseInt(this.imageHeight)}/`
            );
          }
          let smartCropUrl = pictures?.[0]?.url?.replace(
            "/pois/",
            "/smartcrop/" + this.imageWidth + "/" + this.imageHeight + "/pois/"
          );
          smartCropUrl = smartCropUrl.replace("https://", "");
          smartCropUrl = smartCropUrl.replace(/\/\//g, "/");
          return `https://${smartCropUrl}`;
        }
      } else if (this.useFallbackPicture && this.fallbackPicture) {
        // fallback picture

        // right image size?
        if (
          this.fallbackPicture &&
          this.fallbackPicture.imageSizes &&
          this.poi &&
          this.fallbackPicture.imageSizes[aspect]
        ) {
          return this.fallbackPicture?.imageSizes?.[aspect]?.url?.replace(
            "focus",
            "/mainimage/" + this.imageWidth + "/"
          );
        }

        let smartCropUrl = this.fallbackPicture?.url?.replace(
          "pois",
          "/smartcrop/" + this.imageWidth + "/" + this.imageHeight + "/pois/"
        );
        smartCropUrl = smartCropUrl.replace("https://", "");
        smartCropUrl = smartCropUrl.replace(/\/\//g, "/");
        return `https://${smartCropUrl}`;
      }

      return "";
    },
    primaryColor() {
      return this.$vuetify.theme.currentTheme.primary;
    },
    categoryGroups() {
      return this.$store.state.categoryGroups;
    },
    categoryTypes() {
      return this.$store.state.categoryTypes;
    },
    categories() {
      return flatten(this.categoryGroups.map(ct => ct.categories));
    },
    poiCategoryIcon() {
      const category = this.categories.find(
        cat => cat._id === this.poi?.category
      );

      return category?.pictureUrl;
    }
  },
  methods: {
    showWebcam() {
      window.open(`${this.picLink}`, "_blank");
    },
    imgError(event, failedImage) {
      console.error(`can't load image for '${this.poi.title}'`, failedImage, {
        pictures: this.poi?.pictures,
        webcamLinks: this.poi?.webcamLinks,
        event
      });
      // use fallback-image (grayscale to differentiate between error and no image)
      this.imageHasError = true;
      if (this.pictures[0]?.link) {
        this.pictures[0].linkError = this.pictures[0].link;
        this.pictures[0].link = false;
        return;
      }
      if (this.pictures[0]?.url) {
        this.pictures[0].urlError = event;
        this.pictures[0].url = false;
      }
    },
    toggleShowPictureCopyright() {
      if (this.showFullCopyright) this.showFullCopyright = false;
      if (!this.showFullCopyright) this.showFullCopyright = true;
    },
    urlIsPictureFallback(url) {
      if (this.showCopyrightInList) {
        // removes autocrop from url f.e. autocrop/600/337/
        url = url.replace("https://", "");
        url = url.replace(/\/\//g, "/");
        url = `https://${url}`;
        url = url.replace(/mainimage\/\d+\/\d+\/\d+\/\d+\/\d+\//, "");
        url = url.replace(/autocrop\/\d+\/\d+\//, "");
        url = url.replace(/smartcrop\/\d+\/\d+\//, "");
        if (url.includes(this.fallbackPicture.url)) {
          return true;
        }
      }
    },
    checkStep(step, poi) {
      checkStep(step, poi, this.categoryGroups, this.categoryTypes);
    },
    diffBox,
    difficulty,
    opChain,
    setImageHeight() {
      if (this.$refs?.poiImage?.clientWidth > 0) {
        this.height = this.$refs.poiImage.clientWidth / (16 / 9);
      }
    }
  },
  mounted() {
    window.addEventListener("resize", this.setImageHeight);
    setInterval(this.setImageHeight, 1000);
  }
};
