import L from "leaflet";
import { LMarker } from "vue2-leaflet";
import "@/lib/colors";

export default {
  name: "MapIcon",
  props: {
    latLng: {
      type: Object
    },
    poi: {
      type: Object
    },
    active: {
      type: Boolean,
      default: false
    },
    stroke: {
      type: String,
      default: ""
    },
    fill: {
      type: String,
      default: ""
    },
    isParkingLot: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    icon() {
      let mappingField = this.poi?.categoryObj?.[0]?.mappingField;
      //detail-view poi has no categoryObj. use different approach to get mappingField
      if (!this.poi?.categoryObj) {
        const categoryGroup = this.$store.state.categoryGroups.filter(
          catGroup => catGroup._id === this.poi.categoryGroup
        );
        const cat = categoryGroup?.[0]?.categories.filter(
          cat => cat._id === this.poi.category
        );
        mappingField = cat?.[0]?.mappingField;
        if (mappingField === "Parkplätze") {
          this.isParkingLot = true;
        }
      }

      const fillWinter = this.active
        ? this.$store.state.mapPinFillColorActive
        : this.$store.state.mapPinFillColorWinter;
      const strokeWinter = this.active
        ? this.$store.state.mapPinStrokeColorActive
        : this.$store.state.mapPinFillColorWinter;
      const fill = this.active
        ? this.$store.state.mapPinFillColorActive
        : this.$store.state.mapPinFillColor;
      const stroke = this.active
        ? this.$store.state.mapPinStrokeColorActive
        : this.$store.state.mapPinFillColor;
      const fillSymbol = this.active
        ? this.$store.state.mapPinStrokeColorActive
        : "#fff";
      const closedParkingFill = this.active ? "transparent" : this.$mainRed;
      const closedParkingStroke = this.active ? this.$mainRed : "transparent";
      const opendParkingFill = this.active
        ? "transparent"
        : this.$store.state.mapPinFillColorOpen;
      const opendParkingStroke = this.active
        ? this.$store.state.mapPinStrokeColorOpen
        : "transparent";
      let pin = `<svg xmlns="http://www.w3.org/2000/svg" height="39" width="26">
            <path  d="M18.2 13a5.2 5.2 0 01-5.2 5.2A5.2 5.2 0 017.8 13 5.2 5.2 0 0113 7.8a5.2 5.2 0 015.2 5.2zM.5 13L13 38l12.5-25a12.5 12.5 0 00-25 0z"
            fill="${fill}" stroke="${stroke}"/>
          </svg>`;

      let inner = "";
      switch (mappingField[0]) {
        case "Badeseen":
          inner = `
            <g transform="translate(242 3252.302)">
              <path fill="${fillSymbol}"
                    d="M2,18c2.22-1,4.44-2,6.67-2s4.44,2,6.66,2,4.45-2,6.67-2v3c-2.22,0-4.44,2-6.67,2s-4.44-2-6.66-2S4.22,20,2,21V18m6.67-5a9.2,9.2,0,0,0-2.32.32l4.92-3.44L10.23,8.64A1.021,1.021,0,0,1,10,8a1,1,0,0,1,.44-.83l5.72-4L17.31,4.8,12.47,8.19l5.23,6.23a6.247,6.247,0,0,1-2.37.58c-2.22,0-4.44-2-6.66-2M18,7a2,2,0,1,1-2,2A2,2,0,0,1,18,7Z"
                    transform="translate(2 3)"/>
            </g>`;
          break;
        case "Bergbahnen":
          inner = `
            <g transform="translate(244 3254.302)">
              <path fill="${fillSymbol}"
              d="M18,10H13V7.59l9.12-1.52-.24-1.48-5.47.91A1.587,1.587,0,0,0,16.5,5a1.5,1.5,0,1,0-3,0,1.454,1.454,0,0,0,.34.93L13,6.07V5H11V6.41l-.59.09A1.587,1.587,0,0,0,10.5,6a1.5,1.5,0,1,0-3,0,1.482,1.482,0,0,0,.33.93l-5.95,1,.24,1.48L11,7.93V10H6a2,2,0,0,0-2,2v6a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V12a2,2,0,0,0-2-2M6,12H8.25v4H6V12m3.75,4V12h4.5v4H9.75M18,16H15.75V12H18Z"/>
            </g>`;
          break;
        case "Freizeit & Aktivität":
          inner = `
            <g transform="translate(245 3254.302)">
              <path fill="${fillSymbol}"
              d="M12,2a5.975,5.975,0,0,1,6,5.95C18,12.41,12,19,12,19S6,12.41,6,7.95A5.975,5.975,0,0,1,12,2m0,4a2,2,0,1,0,2,2,2,2,0,0,0-2-2m8,13c0,2.21-3.58,4-8,4s-8-1.79-8-4c0-1.29,1.22-2.44,3.11-3.17l.64.91C6.67,17.19,6,17.81,6,18.5,6,19.88,8.69,21,12,21s6-1.12,6-2.5c0-.69-.67-1.31-1.75-1.76l.64-.91C18.78,16.56,20,17.71,20,19Z"/>
            </g>`;
          break;
        case "Touren & Wege":
          inner = `<g transform="translate(245 3252.302)">
                <path fill="${fillSymbol}"
                d="M13.5,5.5a2,2,0,1,0-2-2A2.006,2.006,0,0,0,13.5,5.5Zm4,5.28A4.808,4.808,0,0,1,14.7,8.6L13.7,7a2.155,2.155,0,0,0-1.84-1,1.79,1.79,0,0,0-1.78,1.44C9.89,8.38,7,23,7,23H9.1l1.8-8L13,17v6h2V15.5l-2.1-2,.6-3a7.3,7.3,0,0,0,4,2.34V23H19V9H17.5ZM7.43,13.13l-2.12-.41a1,1,0,0,1-.79-1.17l.76-3.93A2,2,0,0,1,7.62,6.04l1.16.23Z"/>
              </g>`;
          if (this.poi?.categoryObj[0]?.pictureUrl === "mdi-bicycle") {
            inner = `
              <g transform="translate(245 3252.302)">
                <path fill="${fillSymbol}"
                      d="M18.18,10l-1.7-4.68A2.008,2.008,0,0,0,14.6,4H12V6h2.6l1.46,4H11.25l-.36-1H12V7H7V9H8.75l1.82,5H9.9a4.93,4.93,0,0,0-4.65-3.99A5.069,5.069,0,0,0,0,15a4.951,4.951,0,0,0,5,5,4.931,4.931,0,0,0,4.9-4h4.2a4.93,4.93,0,0,0,4.65,3.99,5.077,5.077,0,0,0,5.25-5,4.951,4.951,0,0,0-5-5h-.82ZM7.82,16A2.949,2.949,0,0,1,5,18a3,3,0,0,1,0-6,2.949,2.949,0,0,1,2.82,2H5v2Zm6.28-2H12.7l-.73-2H15A4.734,4.734,0,0,0,14.1,14ZM19,18a3,3,0,0,1-1.95-5.28l.96,2.64,1.88-.68-.97-2.67c.03,0,.06-.01.09-.01A3,3,0,1,1,19,18Z"/>
              </g>
            `;
          }
          break;
        case "Hütten & Alpen":
          inner = `
            <g transform="translate(247 3256.302)">
              <path fill="${fillSymbol}"
              d="M13.765,3,2,13.588H5.529V23H22V13.588h3.529L13.765,3m0,5.529A4.469,4.469,0,0,1,18.235,13c0,3.529-4.471,7.647-4.471,7.647S9.294,16.529,9.294,13a4.469,4.469,0,0,1,4.471-4.471m0,2.706A1.765,1.765,0,1,0,15.529,13,1.765,1.765,0,0,0,13.765,11.235Z"
              transform="translate(-5 -3)"/>
            </g>`;
          break;
        case "Winterwandern":
          inner = `<g transform="translate(247 3256.302)">
              <path fill="${fillSymbol}"
              d="M12.5,3.5a2,2,0,1,1,2,2A2.006,2.006,0,0,1,12.5,3.5ZM6.32,19.03,5.18,17.56,4,18.5l2.38,3.04a4.283,4.283,0,0,0,1.88,1.41.725.725,0,0,0,.72-.11.745.745,0,0,0,.12-1.07.839.839,0,0,0-.31-.22,2.969,2.969,0,0,1-1.14-.83l-.08-.1L11,18.2l.89-3.22,2.11,2V21.5H12V23h3.87a4.322,4.322,0,0,0,2.26-.61.723.723,0,0,0,.37-.64.753.753,0,0,0-.77-.75.787.787,0,0,0-.37.1,3.123,3.123,0,0,1-1.36.4V15.48l-2.11-2,.6-3A7.361,7.361,0,0,0,20,13V11a4.952,4.952,0,0,1-4.31-2.42L14.69,7a2.062,2.062,0,0,0-1.7-1c-.75,0-1.41.34-5.99,2.28V13H9V9.58l1.79-.7L9.2,17Z"
              transform="translate(-5 -3)"/>
            </g>`;
          break;
        case "Rodeln":
          inner = `<g transform="translate(247 3256.302)">
              <path fill="${fillSymbol}"  
             d="M22.91,16.64a4.11,4.11,0,0,0-.94-1.83,3.82,3.82,0,0,0-1.77-1.12l-.43,1.45a2.276,2.276,0,0,1,1.46,1.22,2.451,2.451,0,0,1,.14,1.91,2.477,2.477,0,0,1-1.25,1.46,2.311,2.311,0,0,1-1.89.13L14.9,18.79l.47-1.41,3.33,1.08L19.16,17l-1.96-.6-2.06-4.49a2.034,2.034,0,0,0-.87-.94A1.931,1.931,0,0,0,13,10.74l-2.12.37,1.03-3.27a1.681,1.681,0,0,0-.1-1.34,1.63,1.63,0,0,0-1.07-.91,1.815,1.815,0,0,0-1.27.09L4,8v4.1l-1.6-.52L1.94,13l3.93,1.3L5.4,15.7,1.47,14.44,1,15.84l16.76,5.48a3.8,3.8,0,0,0,2.06.09,4.326,4.326,0,0,0,1.83-.91,4.059,4.059,0,0,0,1.12-1.76,3.9,3.9,0,0,0,.14-2.1M6,9.33,7.93,8.5,6.9,11.67a1.716,1.716,0,0,0,.14,1.41L6,12.75V9.33m7.5,8.99L6.81,16.17l.46-1.41,6.65,2.16-.42,1.4M9.47,13.87,13.45,13l1.17,2.56L9.47,13.87M14,3a2,2,0,1,1-2-2A1.993,1.993,0,0,1,14,3Z" 
             transform="translate(-5 -3)"/>
            </g> `;
          break;
        case "Langlaufen":
          inner = `<g transform="translate(247 3256.302)">
              <path fill="${fillSymbol}"  
d="M19.672,5.576A1.487,1.487,0,0,1,21.25,4a1.461,1.461,0,0,1,1.517,1.436,1.55,1.55,0,1,1-3.1.142Zm2.306,13.292c-.87-1.6-1.679-3.075-2.468-4.552.243-.87.445-1.639.728-2.65.526.688.87,1.133,1.234,1.618,1.234-.04,2.448.121,3.7-.04v9.812h.4V13.183c.121-.02.263-.061.384-.081-.04-.506-.061-.951-.081-1.477-1.153-.061-2.246-.121-3.2-.182C21.27,10.229,21.209,8,19.105,7.579c-1.841.587-3.682,1.173-5.523,1.74v4.066L4.6,14.538l.061.4,8.922-1.133v.344c.526-.061.931-.1,1.4-.162.061-1.133.1-2.165.162-3.257.668-.223,1.275-.4,2.347-.749-1.335,4.9-2.306,9.428-3.642,13.858-1.133.061-2.1.1-3.075.142V25h5.3c.384,0,.749-.061,1.173-.1-.02-.344-.02-.587-.04-.789-.546-.182-.991-.121-1.558-.3.769-2.165,1.517-4.39,2.387-6.777.931,1.275,1.659,2.246,2.327,3.156V23.7c-.546.283-1.335.223-.85,1.214h6.352v-.89c-1.275-.081-2.549-.142-3.884-.223Z"
             transform="translate(-8 -3)"/>
            </g> `;
          break;
        case "Skifahren":
          inner = `<g transform="translate(247 3256.302)">
              <path fill="${fillSymbol}"  
d="M18,10H13V7.59l9.12-1.52-.24-1.48-5.47.91A1.587,1.587,0,0,0,16.5,5a1.5,1.5,0,1,0-3,0,1.454,1.454,0,0,0,.34.93L13,6.07V5H11V6.41l-.59.09A1.587,1.587,0,0,0,10.5,6a1.5,1.5,0,1,0-3,0,1.482,1.482,0,0,0,.33.93l-5.95,1,.24,1.48L11,7.93V10H6a2,2,0,0,0-2,2v6a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V12a2,2,0,0,0-2-2M6,12H8.25v4H6V12m3.75,4V12h4.5v4H9.75M18,16H15.75V12H18Z"              transform="translate(-5 -3)"/>
            </g> `;
          break;
      }

      pin = `
      <svg xmlns="http://www.w3.org/2000/svg" width="41" height="58.024" viewBox="0 0 41 58.024">
        <g transform="translate(-236 -3244.416)">
          <g transform="translate(237.5 3245.916)">
            <path fill="${fillWinter}" stroke="${this.stroke ||
        stroke}" style="stroke-width: 2px;" d="M24,2A19,19,0,0,0,5,21C5,35.25,24,56.286,24,56.286S43,35.25,43,21A19,19,0,0,0,24,2Z"
                  transform="translate(-5 -2)"/>
          </g>
          ${inner}
        </g>
      </svg>
      `;
      const parkingLot =
        this.fill === "transparent"
          ? `
<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 12 12">
    <path class="a"
          d="M10.714,32H1.286A1.286,1.286,0,0,0,0,33.286v9.429A1.286,1.286,0,0,0,1.286,44h9.429A1.286,1.286,0,0,0,12,42.714V33.286A1.286,1.286,0,0,0,10.714,32ZM6.429,39.714H5.143V41a.43.43,0,0,1-.429.429H3.857A.43.43,0,0,1,3.429,41V35a.43.43,0,0,1,.429-.429H6.429a2.571,2.571,0,1,1,0,5.143Zm0-3.429H5.143V38H6.429a.857.857,0,0,0,0-1.714Z"
          transform="translate(0 -32)"
          fill="${closedParkingFill}"
          stroke="${closedParkingStroke}"
          stroke-width="1px"
    />
</svg>

`
          : `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 12 12">
    <path class="a"
          d="M10.714,32H1.286A1.286,1.286,0,0,0,0,33.286v9.429A1.286,1.286,0,0,0,1.286,44h9.429A1.286,1.286,0,0,0,12,42.714V33.286A1.286,1.286,0,0,0,10.714,32ZM6.429,39.714H5.143V41a.43.43,0,0,1-.429.429H3.857A.43.43,0,0,1,3.429,41V35a.43.43,0,0,1,.429-.429H6.429a2.571,2.571,0,1,1,0,5.143Zm0-3.429H5.143V38H6.429a.857.857,0,0,0,0-1.714Z"
          transform="translate(0 -32)"
          fill="${opendParkingFill}"
          stroke="${opendParkingStroke}"
    />
</svg>`;

      if (!inner)
        pin = `
        <svg xmlns="http://www.w3.org/2000/svg" height="39" width="26">
            <path d="M18.2 13a5.2 5.2 0 01-5.2 5.2A5.2 5.2 0 017.8 13 5.2 5.2 0 0113 7.8a5.2 5.2 0 015.2 5.2zM.5 13L13 38l12.5-25a12.5 12.5 0 00-25 0z"
                  fill="${fillWinter}" stroke="${strokeWinter}"/>
        </svg>`;

      return L.divIcon({
        className: "eopoi-map-marker",
        html: this.isParkingLot ? parkingLot : pin,
        iconSize: [26, 39],
        iconAnchor: [13, 39],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28]
      });
    }
  },
  components: {
    LMarker
  },
  methods: {
    onMouseEnter() {
      // bubble event
      this.$emit("mouseenter");
    },
    onMouseLeave() {
      // bubble event
      this.$emit("mouseleave");
    }
  }
};
