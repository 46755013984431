import SelectableButtonWithDropdown from "@/components/SelectableButtonWithDropdown/SelectableButtonWithDropdown.vue";
import Attach from "@/components/Attach/Attach.vue";
import debounce from "lodash/debounce";
import "./ListFilter.scss";
import flatten from "lodash/flatten";

export default {
  data: function() {
    return {
      ...this.showTypeFilter,
      showSearch: false,
      showMobileSearchButton: true,
      menus: {},
      stateItems: [
        {
          id: "open",
          title: "Jetzt geöffnet",
          icon: "mdi-checkbox-blank-circle",
          iconColor: this.$mainOpenGreen
        },
        {
          id: "closed",
          title: "Jetzt geschlossen/gesperrt",
          icon: "mdi-minus-circle",
          iconColor: this.$mainRed
        }
      ],
      // Save $ref to scrollableMenu in data to make sure it can be accessed.
      scrollableMenu: null,
      // Counter to trigger computed property validating the referenced DOM element's overflow.
      scrollableMenuReferenced: 0,
      scrollableMenuScrolling: false,
      // Set Direction for scroll via arrows.
      ScrollDirection: {
        Left: "left",
        Right: "right",
        None: ""
      },
      manualScrollDirection: "",
      manualScrollInterval: null
    };
  },
  props: [
    "showDifficultyFilter",
    "settings",
    "countByFeature",
    "countByCategory",
    "totalCount",
    "showMap",
    "showTypeFilter",
    "searchTerm",
    "selectedMappingField",
    "selectedCategories",
    "selectedFeatures",
    "viewType",
    "showFilterDialog",
    "categories",
    "showFilter"
  ],
  mounted() {
    window.addEventListener("resize", this.forceReferenceScrollableMenu);
    this.forceReferenceScrollableMenu();
  },
  unmounted() {
    window.removeEventListener("resize", this.forceReferenceScrollableMenu);
  },
  methods: {
    getLabelById(id) {
      return this.$store.state.labelsById[id] || "???";
    },
    openSearch() {
      this.showSearch = !this.showSearch;
      this.showMobileSearchButton = !this.showMobileSearchButton;
    },
    removeSearchTerm() {
      this.$emit("removeSearchTerm");
    },
    removeSelectedCategory(id) {
      this.$emit("removeSelectedCategory", id);
    },
    removeSelectedFeature(id) {
      this.$emit("removeSelectedFeature", id);
    },
    getCategoriesForMappingField(mappingField) {
      if (mappingField._id !== this.selectedMappingField) {
        return [];
      }
      const categoryTypes = this.$store.state.categories.filter(categoryType =>
        categoryType.categories?.some(category =>
          category.mappingField.includes(mappingField._id)
        )
      );
      const categories = flatten(
        categoryTypes.map(categoryType => categoryType.categories)
      );
      const uiConfig = this.settings.apiKey?.searchFilterUiElements;
      const isWhitelist = !!uiConfig?.includedCategories?.length;
      return categories.filter(
        category =>
          category.mappingField.includes(mappingField._id) &&
          !uiConfig?.excludedCategories?.includes(category._id) &&
          (!isWhitelist || uiConfig?.includedCategories?.includes(category._id))
      );
    },
    updateSelectableButtons() {
      this.$refs.selectableButtons.forEach(button => {
        button.$forceUpdate();
      });
    },
    setSelectedCategories(selectedCategories) {
      this.$emit("setSelectedCategories", selectedCategories);
    },
    setSelectedMappingField(selectedMappingField) {
      this.$emit("setSelectedMappingField", selectedMappingField);
    },
    favoritesFiltered() {
      return !!this.$store.state.filterFavorites;
    },
    setFilterDialog() {
      this.$emit("setFilterDialog", true);
    },
    checkSeason(season) {
      return this.currentSeason === season;
    },
    setDropDownPosition() {
      this.scrollableMenuScrolling = true;
      const dropdown = this.scrollableMenu?.querySelector(
        ".v-menu__content.menuable__content__active"
      );
      if (!dropdown) {
        this.scrollableMenuScrolling = false;
        return;
      }
      const offset = -this.scrollableMenu.scrollLeft;
      dropdown.style.transform = `translateX(${offset}px)`;
      this.scrollableMenuScrolling = false;
    },
    forceReferenceScrollableMenu() {
      this.scrollableMenuReferenced++;
    },
    scrollMenu(direction) {
      const scrollBy = this.isChromeBrowser ? 150 : 100;
      let scrollToLeft;
      let maxScrollReached = false;
      if (direction === this.ScrollDirection.Left) {
        scrollToLeft = this.scrollableMenu.scrollLeft - scrollBy;
        maxScrollReached = Math.max(scrollToLeft, 0) === 0;
      } else if (direction === this.ScrollDirection.Right) {
        scrollToLeft = this.scrollableMenu.scrollLeft + scrollBy;
        maxScrollReached =
          Math.min(scrollToLeft, this.scrollableMenu.scrollWidth) ===
          this.scrollableMenu.scrollWidth;
      }
      this.scrollableMenu.scrollTo({
        left: scrollToLeft,
        behavior: "smooth"
      });
      if (maxScrollReached) {
        this.stopMenuScroll();
      }
    },
    startMenuScroll(direction) {
      this.manualScrollDirection = direction;
    },
    stopMenuScroll() {
      this.manualScrollDirection = this.ScrollDirection.None;
    }
  },
  computed: {
    currentSeason() {
      if (!this.apiKeyObj?.season)
        return this.$store.state.isWinter ? "winter" : "summer";
      if (this.apiKeyObj.season === "principalSeason")
        return this.settings.currentSeason;
      return this.apiKeyObj.season;
    },
    cShowMap: {
      get() {
        return this.showMap;
      },
      set(value) {
        this.$emit("toggleMap", value);
      }
    },
    selectedViewType: {
      get() {
        return this.viewType;
      },
      set(value) {
        this.$emit("changeViewType", value);
      }
    },
    search: {
      get() {
        return this.searchTerm;
      },
      set: debounce(function(searchValue) {
        if (searchValue.length === 0 || searchValue.length >= 3)
          this.$store.commit("SET_SEARCH", searchValue);
        this.$emit("reload");
      }, 500)
    },
    prependIcon() {
      if (this.selectedState === "open") return "mdi-checkbox-blank-circle";
      else if (this.selectedState === "closed") return "mdi-minus-circle";
      else return "mdi-checkbox-blank-circle-outline";
    },
    prependIconColor() {
      if (this.selectedState === "open") return this.$mainOpenGreen;
      else if (this.selectedState === "closed") return this.$mainRed;
      else return this.$mainBlue;
    },
    selectedState: {
      get() {
        return this.$store.state.openFilter;
      },
      set(value) {
        this.$store.commit("SET_OPEN_FILTER", value);
        this.$emit("reload");
      }
    },
    isReport() {
      return this.settings?.apiKey?.isReport;
    },
    apiKeyObj() {
      return this.settings?.apiKey ?? {};
    },
    mappingFields() {
      const mappingFields = this.checkSeason("summer")
        ? this.$store.state.mappingFields.filter(field => field.summer)
        : this.$store.state.mappingFields.filter(field => field.winter);

      if (this.apiKeyObj?.includedMappingFields?.length) {
        return mappingFields.filter(mappingField =>
          this.apiKeyObj.includedMappingFields.includes(mappingField._id)
        );
      } else return mappingFields;
    },
    scrollableMenuOverflows() {
      // Case is never true. Check only to force recomputing the property.
      if (!this.scrollableMenuReferenced) {
        return false;
      }
      if (!this.scrollableMenu) {
        return false;
      }
      return this.scrollableMenu.clientWidth < this.scrollableMenu.scrollWidth;
    },
    scrollableMenuScrolledToEnd() {
      if (this.scrollableMenuScrolling || !this.scrollableMenuReferenced) {
        return false;
      }
      const { scrollWidth, scrollLeft, clientWidth } = this.scrollableMenu;
      const scrolledToEnd =
        scrollWidth - scrollLeft - clientWidth < this.lastThemeButtonWidth / 3;
      if (
        this.manualScrollDirection === this.ScrollDirection.Right &&
        scrolledToEnd
      ) {
        this.stopMenuScroll();
      }
      return scrolledToEnd;
    },
    scrollableMenuScrolledToStart() {
      if (this.scrollableMenuScrolling || !this.scrollableMenuReferenced) {
        return false;
      }
      const scrolledToStart =
        this.scrollableMenu.scrollLeft < this.firstThemeButtonWidth / 3;
      if (
        this.manualScrollDirection === this.ScrollDirection.Left &&
        scrolledToStart
      ) {
        this.stopMenuScroll();
      }
      return scrolledToStart;
    },
    firstThemeButtonWidth() {
      if (!this.scrollableMenuReferenced) {
        return 0;
      }
      const firstThemeButton = this.scrollableMenu.querySelector(
        "[data-first-mapping-field='true']"
      );
      return firstThemeButton.getBoundingClientRect().width;
    },
    lastThemeButtonWidth() {
      if (!this.scrollableMenuReferenced) {
        return 0;
      }
      const lastThemeButton = this.scrollableMenu.querySelector(
        "[data-last-mapping-field='true']"
      );
      return lastThemeButton.getBoundingClientRect().width;
    },
    isChromeBrowser() {
      return !!window.chrome;
    }
  },
  watch: {
    scrollableMenuReferenced() {
      this.$nextTick(() => {
        const interval = setInterval(() => {
          if (!this.$refs.scrollableMenu) {
            return;
          }
          clearInterval(interval);
          this.scrollableMenu = this.$refs.scrollableMenu;
        }, 200);
      });
    },
    manualScrollDirection(direction) {
      if (!this.scrollableMenu) {
        return;
      }
      if (direction === this.ScrollDirection.None) {
        clearInterval(this.manualScrollInterval);
        return;
      }
      this.manualScrollInterval = setInterval(
        () => {
          this.scrollMenu(direction);
        },
        this.isChromeBrowser ? 225 : 75
      );
    }
  },
  components: {
    SelectableButtonWithDropdown,
    Attach
  }
};
