import { checkStep } from "@/helpers/detailViewHelper";
import "@/lib/colors";
import "./PoiOpenClosedIcons.scss";

export default {
  props: {
    poi: {
      type: Object,
      required: true
    }
  },
  computed: {
    categoryGroups() {
      return this.$store.state.categoryGroups;
    },
    categoryTypes() {
      return this.$store.state.categoryTypes;
    },
    isParkingLot() {
      return checkStep(
        "parkingLot",
        this.poi,
        this.categoryGroups,
        this.categoryTypes
      );
    },
    icon() {
      switch (this.poi.state.state) {
        case "open":
          if (this.isParkingLot) return "mdi-alpha-p-box";
          else return "mdi-checkbox-blank-circle";
        case "seasonEnd":
          return "mdi-calendar-remove";
        case "closed":
          if (this.isParkingLot) return "mdi-alpha-p-box";
          else return "mdi-minus-circle";
        case "openingHours":
          return "mdi-checkbox-blank-circle-outline";
        case "partiallyOpen":
          return "mdi-circle-half-full";
        case "avalancheLock":
          return "mdi-triangle";
        case "":
          return "mdi-checkbox-blank-circle-outline";
        case "capacityExhausted":
        case "highWorkload":
          return "mdi-alpha-p-box";
      }
    },
    iconColor() {
      switch (this.poi.state.state) {
        case "capacityExhausted":
          return this.$mainYellow;
        case "partiallyOpen":
          return this.$mainYellow;
        case "closed":
          return this.$mainRed;
        case "highWorkload":
          return this.$mainRed;
        case "avalancheLock":
          return this.$mainRed;
        case "":
          return this.$mainBlue;
        default:
          return this.$mainOpenGreen;
      }
    }
  }
};
