import { getFromStorage } from "@/helpers/storageHelper";

function ensureArray(value) {
  if (value && !Array.isArray(value)) {
    return value.split(",");
  }
  return value;
}
export default (namespace = "eopoi", defaultValues = {}) => ({
  ...defaultValues,
  favorites: ensureArray(getFromStorage(`${namespace}-favorites`)) || [],
  mappingFields: getFromStorage(`${namespace}-mappingFields`) || [],
  selectedCategories:
    ensureArray(getFromStorage(`${namespace}-categories`)) || [],
  selectedLocation: getFromStorage(`${namespace}-location`),
  selectedFeatures: ensureArray(getFromStorage(`${namespace}-features`)) || [],
  selectedCities: ensureArray(getFromStorage(`${namespace}-cities`)) || [],
  filterFavorites: getFromStorage(`${namespace}-filter-favorites`),
  sort: getFromStorage(`${namespace}-filter-sort`) || "",
  direction: getFromStorage(`${namespace}-filter-direction`) || "",
  selectedMappingField: getFromStorage(`${namespace}-mapping-field`) || "",
  openFilter: getFromStorage(`${namespace}-openFilter`),
  mapSwitch: getFromStorage(`${namespace}-mapSwitch`),
  difficulty: getFromStorage(`${namespace}-difficulty`),
  listLoading: false,
  tasksLoading: {},
  s: getFromStorage(`${namespace}-filter-search`) || "",
  namespace,
  showWinterSeason: true,
  isWinter: false,
  globalSeason: "",
  categories: [],
  features: [],
  rolloverCardLocationId: undefined,
  rolloverMapLocationId: undefined,
  labelsById: {},
  categoryGroups: [],
  categoryTypes: [],
  relatedPois: [],
  regionPois: [],
  cities: []
});
