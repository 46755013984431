import { getConfig } from "@/helpers/apiHelper";
import moment from "moment";

export function getDifficultyText(difficulty) {
  switch (difficulty) {
    case "easy":
      return "Leicht";
    case "intermediate":
      return "Mittel";
    case "hard":
      return "Schwer";
  }
}

export function fixUrl(url) {
  if (!/^((http|https|ftp):\/\/)/.test(url)) {
    url = "http://" + url;
  }
  return url;
}

export function stripTags(str) {
  return str ? str.replace(/(<([^>]+)>)/gi, "") : "";
}

export function decodeHtmlEntities(str) {
  if (!str?.length) {
    return "";
  }
  let txt = document.createElement("textarea");
  txt.innerHTML = str;
  return txt.value;
}

export function getSeasonOrAnyDescription(
  poi,
  isWinter = false,
  stripTheTags = true,
  preferShort = false
) {
  let description = "";
  if (isWinter) {
    description =
      poi.description?.seasonalDescription ||
      poi.shortDescription?.seasonalDescription;
    if (preferShort) {
      description =
        poi.shortDescription?.seasonalDescription ||
        poi.description?.seasonalDescription;
    }
  }
  //summer or fallback
  if (!description) {
    description =
      poi.description?.description || poi.shortDescription?.description;
    if (preferShort) {
      description =
        poi.shortDescription?.description || poi.description?.description;
    }
  }

  if (stripTheTags) return stripTags(description);
  return description;
}

export async function getSettings() {
  try {
    const data = await getConfig(this.$store.state.apiUrl, this.apiKey);
    this.$store.commit("SET_GLOBAL_SEASON", data[0]?.currentSeason ?? "");
    if (data && data.length && data[0].apiKeys && data[0].apiKeys.length) {
      this.settings = data[0];
      this.settings.apiKey = this.settings.apiKeys[0]; // find first API key setting since this will be ours
      delete this.settings.apiKeys;
    }
  } catch (e) {
    this.error = e;
  }
}

export function getLocation(
  { city, district, country } = {},
  { displayLocationCity, displayLocationDistrict, displayLocationCountry } = {}
) {
  let locationText = "";
  let hasCity = false;

  if (displayLocationCity && city) {
    locationText += city;
    hasCity = true;
  }
  if (displayLocationDistrict && district) {
    locationText += (hasCity ? " - " : "") + district;
  }
  if (displayLocationCountry && country) {
    locationText += locationText ? ", " + country : country;
  }
  return locationText;
}

export function fixFeaturesAndCategoriesForQuery(query, settings) {
  if (
    settings.apiKey.containByCategoryGroupIds &&
    settings.apiKey.containByCategoryGroupIds.length
  ) {
    query.containByCategoryGroupIds = settings.apiKey.containByCategoryGroupIds;
  }
  if (
    settings.apiKey.containByCategoryTypeIds &&
    settings.apiKey.containByCategoryTypeIds.length
  ) {
    query.containByCategoryTypeIds = settings.apiKey.containByCategoryTypeIds;
  }
  if (
    settings.apiKey.containByCategoryIds &&
    settings.apiKey.containByCategoryIds.length
  ) {
    query.containByCategoryIds = settings.apiKey.containByCategoryIds;
  }

  // manually contain features
  if (
    settings.apiKey.containByFeatureIds &&
    settings.apiKey.containByFeatureIds.length
  ) {
    query.containByFeatureIds = settings.apiKey.containByFeatureIds;
  }
}

export const checkForImage = pic => {
  const url = (pic.url || pic.link)?.toLowerCase();
  return url?.includes("jpg") || url?.includes("jpeg") || url?.includes("png");
};
export const mToKm = m =>
  (m / 1000)
    .toFixed(1)
    .toString()
    .replace(".0", "")
    .replace(".", ",");

export function getSnowTexture(snowTexture) {
  const snowTextureItems = [
    { id: "oldSnow", title: "Altschnee" },
    { id: "solidOldSnow", title: "Fester Altschnee" },
    { id: "moistOldSnow", title: "Feuchtnasser Altschnee" },
    { id: "powderSnow", title: "Pulverschnee" },
    { id: "mashSnow", title: "Sulzschnee" },
    { id: "cardBoardSnow", title: "Pappschnee" },
    { id: "maschineSnow", title: "Maschinenschnee" },
    { id: "drySnow", title: "Trockener Neuschnee" },
    { id: "boundFreshSnow", title: "Gebundener Neuschnee" },
    { id: "frozenSnow", title: "Harsch" },
    { id: "firnSnow", title: "Firnschnee" }
  ];

  return (
    snowTextureItems.find(item => item.id === snowTexture)?.title || "k.A."
  );
}

export function getWeatherCondition(weatherCondition) {
  const weatherConditionItems = [
    { id: "bright", title: "Heiter" },
    { id: "slightlyCloudy", title: "Leicht bewölkt" },
    { id: "cloudy", title: "Bewölkt" },
    { id: "foggy", title: "Nebelig" },
    { id: "drizzle", title: "Nieselregen" },
    { id: "shudder", title: "Schauer" },
    { id: "rain", title: "Regen" },
    { id: "snowfall", title: "Schneefall" },
    { id: "lightSnow", title: "Leichter Schneefall" },
    { id: "sleet", title: "Schneeregen" },
    { id: "blizzard", title: "Schneesturm" },
    { id: "cloudless", title: "Wolkenlos" }
  ];

  return (
    weatherConditionItems.find(item => item.id === weatherCondition)?.title ||
    "k.A."
  );
}

export function getSight(sight) {
  const sightItems = [
    { id: "goodVision", title: "Gute Fernsicht" },
    { id: "foggy", title: "Nebelig" }
  ];

  return sightItems.find(item => item.id === sight)?.title || "k.A.";
}

export function diffBox(label, difficultyName) {
  const color = this.$store.state.difficultyColors[difficultyName];
  return `<div class="diffBox" style="color: ${color};border-color: ${color};">${label}</div>`;
}

export function difficulty(p) {
  const diff = p?.state?.difficulty;
  switch (diff) {
    case "hard":
      return this.diffBox("Schwer", diff);
    case "intermediate":
      return this.diffBox("Mittel", diff);
    case "easy":
      return this.diffBox("Leicht", diff);
    default:
      return this.diffBox("k.A.", "ka");
  }
}

export function getConditionText(condition) {
  switch (condition) {
    case "street":
      return "Teer / Straße";
    case "gravel":
      return "Kies / Schotter";
    case "meadowPath":
      return "Wiesenweg";
    case "forestFloor":
      return "Waldboden";
    case "mountainPath":
      return "Bergpfad";
    case "steepTrack":
      return "Steig";
  }
}

export function getLocationText(location) {
  switch (location) {
    case "valley":
      return "Tal";
    case "intermediateHeight":
      return "Mittlere Höhe";
    case "highAlpine":
      return "Hochalpin";
  }
}

export function getSlopeCondition(slopeCondition) {
  switch (slopeCondition) {
    case "veryGood":
      return "Sehr Gut";
    case "good":
      return "Gut";
    case "okay":
      return "Befriedigend";
    case "bad":
      return "Schlecht";
  }
}

export function toDate(date) {
  return moment(date).format("DD.MM.YYYY");
}

export function arrayIsEmpty(array) {
  return !array?.length || !array.some(item => !!item);
}

export function enumArrayToString(enumArr, mapFunc, joinWith = ", ") {
  if (arrayIsEmpty(enumArr)) {
    return "";
  }
  if (typeof mapFunc !== "function") {
    mapFunc = value => value;
  }
  return enumArr
    .filter(value => !!value)
    .map(mapFunc)
    .join(joinWith);
}
