var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('Attach',{ref:"sbwdTarget"}),_c('v-item-group',[_c('v-item',[_c('div',{staticClass:"d-inline-flex",attrs:{"id":"selectable_button_with_dropdown"}},[_c('v-btn',{class:{
            selected: _vm.isButtonSelected,
            no_right_border:
              _vm.items && _vm.items.length > 1 && !_vm.cSelectedItems.length
          },attrs:{"color":"white","id":"selectable_button"},on:{"click":function($event){return _vm.handleInput(_vm.buttonValue)}}},[_vm._v(" "+_vm._s(_vm.buttonTitle)+" ")]),(
            _vm.attachRefReady &&
              ((_vm.items && _vm.items.length > 1) ||
                (_vm.cSelectedItems.length && _vm.isButtonSelected))
          )?_c('v-select',{class:{
            'pt-0': true,
            'mt-0': true,
            selected: _vm.isButtonSelected
          },attrs:{"attach":_vm.$refs.sbwdTarget.selector,"menu-props":{ bottom: true, offsetY: true },"height":"42px","items":_vm.items,"multiple":_vm.multiple,"item-value":_vm.itemValue,"item-text":_vm.itemText,"no-data-text":_vm.noDataText || 'Keine Elemente gefunden'},on:{"click":function($event){return _vm.handleInput(_vm.buttonValue)},"input":function($event){return _vm.handleInput(_vm.selectedValue)}},model:{value:(_vm.cSelectedItems),callback:function ($$v) {_vm.cSelectedItems=$$v},expression:"cSelectedItems"}}):_vm._e()],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }