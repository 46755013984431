import "./ListFilterDialog.sass";
import VTooltip from "v-tooltip";
import Vue from "vue";
import "@/styles/v-tooltip.scss";

Vue.use(VTooltip);

export default {
  name: "ListFilter",
  props: {
    settings: Object,
    mappedPois: Object,
    countByFeature: Object,
    countByCategory: Object,
    totalCount: Number,
    showDifficultyFilter: Boolean,
    sortings: Array,
    isReport: Boolean,
    showNotepad: Boolean
  },
  async mounted() {
    if (this.selectedMappingField) {
      this.themeCategoriesVisible[this.selectedMappingField] = 1;
    }
    await this.$store.dispatch("getCategories");
    await this.$store.dispatch("getMappingFields");
    await this.$store.dispatch("getFeatures");
    await this.$store.dispatch("getCities");
    const { s: search, sort, direction } = this.$store.state;

    this.s = search;
    if (sort) {
      this.currentSorting = `${sort}:${direction}`;
    }
  },
  data: () => ({
    s: "", // helper to check whether search field is filled or not
    difficultyItems: [
      { id: "easy", title: "Leicht" },
      { id: "intermediate", title: "Mittel" },
      { id: "hard", title: "Schwer" }
    ],
    currentSorting: "sortTitle:asc",
    themeCategories: [],
    themeCategoriesVisible: [],
    openCategories: false,
    openCities: false
  }),
  computed: {
    selectedMappingField: {
      get() {
        return this.$store.state.selectedMappingField;
      },
      set(value) {
        this.$store.commit("SET_SEARCH", "");
        this.$store.commit("SET_SELECTED_CATEGORIES", []);
        this.$store.commit("SET_SELECTED_FEATURES", []);
        this.$store.commit("SET_SELECTED_CITIES", []);
        this.$store.commit("SET_DIFFICULTY", []);
        this.$store.commit("SET_SELECTED_MAPPING_FIELD", value);
        this.$emit("reload");
      }
    },
    mappingFields() {
      const season = this.checkSeason("winter") ? "winter" : "summer";
      const mappingFields = this.getMappingFields(season);
      if (this.apiKeyObj?.includedMappingFields?.length) {
        return mappingFields.filter(mappingField =>
          this.apiKeyObj?.includedMappingFields?.includes(mappingField._id)
        );
      } else return mappingFields;
    },
    selectedCategories() {
      return this.$store.state.selectedCategories;
    },
    selectedCities() {
      return this.$store.state.selectedCities;
    },
    selectedFeatures() {
      return this.$store.state.selectedFeatures;
    },
    categories() {
      let categoryTypes = [];

      this.$store.state.categories.forEach(categoryType => {
        const uiConfig = this.settings.apiKey?.searchFilterUiElements;
        const isWhitelist = !!uiConfig?.includedCategories?.length;
        const categories = categoryType.categories
          .filter(
            category =>
              !uiConfig?.excludedCategories?.includes(category._id) &&
              (!isWhitelist ||
                uiConfig?.includedCategories?.includes(category._id))
          )
          .map(category => {
            category.typeTitle = categoryType.title; // TODO: später hier mal zweistufige Auswahl!
            return category;
          });

        categoryTypes = categoryTypes.concat(categories);
      });

      return categoryTypes;
    },
    cities() {
      return this.$store.state.cities.filter(city => city?.length);
    },
    filteredCategories() {
      if (!this.selectedMappingField) return [];
      return this.categories.filter(c =>
        c.mappingField.includes(this.selectedMappingField)
      );
    },
    featureGroups() {
      const featureGroups = [];
      this.$store.state.features.forEach(featureGroup => {
        const uiConfig = this.settings.apiKey?.searchFilterUiElements;
        const isWhitelist = !!uiConfig?.includedFeatures?.length;
        const features = featureGroup.features.filter(feature => {
          return (
            !feature.internalControlFeature &&
            !!this.countByFeature[feature._id] &&
            !uiConfig?.excludedFeatures?.includes(feature._id) &&
            (!isWhitelist || uiConfig?.includedFeatures?.includes(feature._id))
          );
        });
        if (features && features.length) {
          featureGroups.push({
            title: featureGroup.title,
            features: features
          });
        }
      });

      return featureGroups;
    },
    searchString() {
      return this.$store.state.s;
    },
    difficulty: {
      get() {
        return this.$store.state.difficulty;
      },
      set(value) {
        this.$store.commit("SET_SELECTED_CATEGORIES", []);
        this.$store.commit("SET_SELECTED_FEATURES", []);
        this.$store.commit("SET_DIFFICULTY", value);
        this.$emit("reload");
      }
    },
    currentSortingTitle() {
      if (this.currentSorting && this.sortings && this.sortings.length) {
        for (let i = 0; i < this.sortings.length; i++) {
          if (this.sortings[i].value === this.currentSorting)
            return this.sortings[i].text;
        }
      }
    },
    themeCategoriesLoading() {
      return this.$store.state.tasksLoading?.["filterCategoriesChange"];
    },
    totalFavorites() {
      return this.$store.state.favorites.length;
    },
    favoritesFiltered() {
      return this.$store.state.filterFavorites;
    },
    apiKeyObj() {
      return this.settings?.apiKey ?? {};
    },
    showCitiesFilter() {
      return this.settings?.apiKey?.showCitiesFilter;
    }
  },
  methods: {
    themeCategoriesToggle() {
      this.themeCategoriesVisible = [];
      this.themeCategoriesVisible[this.selectedMappingField] = 1;
    },
    checkSeason(season) {
      if (
        !this.apiKeyObj.season ||
        this.apiKeyObj.season === "principalSeason"
      ) {
        return this.settings?.currentSeason === season;
      } else return this.apiKeyObj.season === season;
    },
    updateSearchString(searchString) {
      this.$store.commit(
        "SET_SEARCH",
        searchString !== null ? searchString : ""
      );
      this.$emit("reload");
    },
    clickedOnFind() {
      this.updateSearchString();
    },
    getMappingFields(season) {
      return this.$store.state.mappingFields.filter(field => field[season]);
    },
    categoriesChanged(ids) {
      this.$store.commit("SET_SELECTED_CATEGORIES", ids);
      this.$emit("reload");
    },
    citiesChanged(cities) {
      this.$store.commit("SET_SELECTED_CITIES", cities);
      this.$emit("reload");
    },
    singleCategoryChanged(id, event) {
      let ids = [...this.selectedCategories];
      if (event) {
        //add id
        ids.push(id);
        //remove doubles
        ids = [...new Set([...ids])];
      } else {
        //remove id
        ids = ids.filter(c => c !== id);
      }
      this.categoriesChanged(ids);
    },
    singleCityChanged(city, event) {
      let cities = [...this.selectedCities];
      if (event) {
        cities.push(city);
        cities = [...new Set([...cities])];
      } else {
        cities = cities.filter(c => c !== city);
      }
      this.citiesChanged(cities);
    },
    featureChanged(id) {
      const selectedFeatures = this.$store.state.selectedFeatures;
      const pos = selectedFeatures.indexOf(id);

      // add entry to list
      if (pos === -1) {
        selectedFeatures.push(id);
      } else {
        selectedFeatures.splice(pos, 1);
      }
      this.$store.commit("SET_SELECTED_FEATURES", selectedFeatures);
      this.$emit("reload");
    },
    selectionChanged() {
      // change store state
      this.$store.commit("SET_SELECTED_FEATURES", this.selectedFeatures);
      this.$emit("reload");
    },
    submit() {
      // just close dialog
      this.$emit("close");
    },
    reset() {
      this.s = "";
      this.$store.commit("SET_SEARCH", "");
      this.$store.commit("SET_SELECTED_CATEGORIES", []);
      this.$store.commit("SET_SELECTED_FEATURES", []);
      this.$store.commit("SET_SELECTED_CITIES", []);
      this.$store.commit("SET_DIFFICULTY", []);
      this.$emit("reload");
    },
    changeSorting(newValue = undefined) {
      if (newValue) this.currentSorting = newValue;

      const parts = this.currentSorting.split(":");
      if (parts.length === 2) {
        this.$store.commit("SET_SORTING", {
          sort: parts[0],
          direction: parts[1]
        });
        // load now
        this.$emit("loadData");
      }
    },
    toggleFilterFavorites() {
      this.$store.commit("TOGGLE_FAVORITES_FILTER");
      this.$emit("reload");
    }
  },
  watch: {
    s() {
      // if clicked on delete cross, update search
      if (!this.s) this.updateSearchString("");
    },
    searchString(newValue) {
      // update local model on changes
      if (newValue !== this.s) {
        this.s = newValue;
      }
    },
    categories() {
      this.$store.commit("SET_TASK_LOADING", {
        taskName: "filterCategoriesChange",
        isLoading: false
      });
    }
  }
};
