var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"poiImage",style:({ height: _vm.height }),attrs:{"id":"poi_picture__wrapper"}},[(_vm.picture)?_c('v-img',{class:{ 'image-error': _vm.imageHasError },attrs:{"height":_vm.height,"src":_vm.picture,"position":"top","lazy-src":require("@/assets/svg/LazyLoad.svg"),"cover":""},on:{"error":function($event){return _vm.imgError($event, _vm.picture)}}},[(_vm.firstPicture.copyright && _vm.showCopyrightInList)?_c('div',{staticClass:"eopoi-picture-copyright__list bg-black-opacity50",on:{"mouseover":function($event){_vm.showFullCopyright = true},"mouseleave":function($event){_vm.showFullCopyright = false}}},[_c('v-btn',{staticClass:"color-white",attrs:{"text":""},on:{"click":_vm.toggleShowPictureCopyright},model:{value:(_vm.showFullCopyright),callback:function ($$v) {_vm.showFullCopyright=$$v},expression:"showFullCopyright"}},[_vm._v("© ")]),(_vm.showFullCopyright)?_c('span',[_vm._v(_vm._s(_vm.firstPicture.copyright))]):_vm._e()],1):_vm._e(),(
        _vm.urlIsPictureFallback(_vm.picture) &&
          _vm.fallbackPicture.copyright &&
          _vm.showCopyrightInList
      )?_c('div',{staticClass:"eopoi-picture-copyright__list bg-black-opacity50",on:{"mouseover":function($event){_vm.showFullCopyright = true},"mouseleave":function($event){_vm.showFullCopyright = false}}},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.toggleShowPictureCopyright},model:{value:(_vm.showFullCopyright),callback:function ($$v) {_vm.showFullCopyright=$$v},expression:"showFullCopyright"}},[_vm._v("© ")]),(_vm.showFullCopyright)?_c('span',[_vm._v(_vm._s(_vm.fallbackPicture.copyright))]):_vm._e()],1):_vm._e()]):_c('div',{staticClass:"eopoi-app-card-placeholder",style:('height:' + _vm.height + 'px')}),(_vm.poi.highlight)?_c('div',{staticClass:"eopoi-picture-highlight",style:({ background: _vm.primaryColor, color: '#fff' })},[_vm._v(" Top-Ort ")]):(_vm.opChain('poi.state.difficulty'))?_c('div',{staticClass:"difficulty-box",domProps:{"innerHTML":_vm._s(_vm.difficulty(_vm.poi))}}):_vm._e(),(_vm.firstPicture.link && !_vm.picLink)?_c('div',{staticClass:"live-label bg-black-opacity50",class:{ inCard: _vm.inCard }},[_vm._v(" Live ")]):_vm._e(),(_vm.picLink)?_c('v-btn',{staticClass:"webcam__label bg-white",class:{ inCard: _vm.inCard },attrs:{"elevation":"0","icon":"","tile":""},on:{"click":function($event){$event.stopPropagation();return _vm.showWebcam.apply(null, arguments)}}},[_c('v-icon',{staticClass:"justify-center d-flex mt-1",style:({ background: '#fff', color: _vm.primaryColor }),attrs:{"color":"primaryColor"}},[_vm._v("mdi-webcam ")])],1):_vm._e(),(_vm.showCopyright)?[(
        _vm.firstPicture.title &&
          !_vm.detailWebcam &&
          !_vm.isImageFilename(_vm.firstPicture.title)
      )?_c('div',{staticClass:"eopoi-picture-caption"},[_vm._v(" "+_vm._s(_vm.firstPicture.title)+" ")]):_vm._e(),(_vm.firstPicture.copyright)?_c('div',{staticClass:"eopoi-picture-copyright",attrs:{"title":_vm.pictureFullCopyRight(_vm.firstPicture)}},[_vm._v(" © "+_vm._s(_vm.firstPicture.copyright)+" ")]):_c('div',{staticClass:"eopoi-picture-copyright"},[_vm._v(" "+_vm._s(_vm.pictureFullCopyRight(_vm.firstPicture))+" ")]),_c('div',{staticClass:"poi_list__image_icon eopoi-picture-icons"},[(_vm.poiCategoryIcon)?_c('v-icon',{staticClass:"bg-black-opacity50",attrs:{"color":"#fff"}},[_vm._v(" "+_vm._s(_vm.poiCategoryIcon)+" ")]):_vm._e(),(_vm.poi.highlight)?_c('v-icon',{staticClass:"bg-black-opacity50 ml-2",attrs:{"color":"#fff"}},[_vm._v(" mdi-star ")]):_vm._e()],1)]:_c('div',[(_vm.poiCategoryIcon)?_c('v-icon',{staticClass:"poi_list__image_icon bg-black-opacity50",attrs:{"color":"#fff"}},[_vm._v(" "+_vm._s(_vm.poiCategoryIcon)+" ")]):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }