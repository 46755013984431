import "./SelectableButtonWithDropdown.scss";
import Attach from "@/components/Attach/Attach.vue";

export default {
  data: () => {
    return {
      targetStatus: ""
    };
  },
  props: {
    selectedValue: String,
    selectedItems: [String, Array],
    items: Array,
    buttonTitle: String,
    buttonValue: String,
    multiple: Boolean,
    itemValue: String,
    itemText: String,
    noDataText: String
  },
  methods: {
    handleInput(value) {
      if (value === this.selectedValue) {
        return;
      }
      this.$emit("setSelectedValue", value);
    },
    selectListItem(id) {
      if (this.multiple) {
        if (!Array.isArray(this.cSelectedItems)) this.cSelectedItems = [];
        if (this.cSelectedItems.includes(id)) {
          const index = this.cSelectedItems.indexOf(id);
          this.cSelectedItems.splice(index, 1);
        } else this.cSelectedItems.push(id);
      } else this.cSelectedItems = id;
    },
    isItemSelected(id) {
      return this.multiple
        ? this.cSelectedItems.includes(id)
        : this.cSelectedItems === id;
    }
  },
  computed: {
    isButtonSelected() {
      return this.selectedValue === this.buttonValue;
    },
    cSelectedItems: {
      get() {
        return this.selectedItems;
      },
      set(value) {
        this.$emit("setSelectedItems", value);
      }
    },
    attachRefReady() {
      if (this.$refs.sbwdTarget || this.targetStatus === "ready") {
        return true;
      }
      setTimeout(() => {
        if (this.$refs.sbwdTarget) {
          this.targetStatus = "ready";
        }
      }, 200);
    }
  },
  components: { Attach }
};
