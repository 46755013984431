import svgIcons from "@/lib/svgIcons";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import appStore from "@/store";
import de from "vuetify/es5/locale/de";
import en from "vuetify/es5/locale/en";

export const icons = {
  iconfont: "mdiSvg",
  values: { ...svgIcons }
};

export const getAttributeValue = (element, attribute) =>
  element.attributes[attribute] && element.attributes[attribute].value;

export const getTheme = element => ({
  themes: {
    light: {
      primary: getAttributeValue(element, "data-color-primary") || "#97bd0f",
      secondary:
        getAttributeValue(element, "data-color-secondary") || "#5d7114",
      accent: getAttributeValue(element, "data-color-accent") || "#2468c9",
      error: getAttributeValue(element, "data-color-error") || "#c55c52",
      info: getAttributeValue(element, "data-color-info") || "#314d57",
      success: getAttributeValue(element, "data-color-success") || "#97bd0f",
      warning: getAttributeValue(element, "data-color-warning") || "#e2d7af",
      summaryPrimary:
        getAttributeValue(element, "data-color-summary-primary") || "#50727C",
      summarySecondary:
        getAttributeValue(element, "data-color-summary-secondary") || "#A8C1C7"
    }
  },
  options: {
    customProperties: true
  }
});

export const difficultyColors = element => ({
  easy: "#0081B8",
  intermediate: "#AF3E4D",
  hard: "#444444",
  ...parseJsonSave(getAttributeValue(element, "data-difficulty-colors"))
});
const getApiUrl = element =>
  process.env.VUE_APP_API_URL?.includes("poi3.api")
    ? process.env.VUE_APP_API_URL
    : getAttributeValue(element, "data-api-url") || process.env.VUE_APP_API_URL;
export const getDefaultStoreValues = element => ({
  targetUri: getAttributeValue(element, "data-target-uri"),
  listUri: getAttributeValue(element, "data-list-uri"),
  summaryUri: getAttributeValue(element, "data-summary-uri"),
  apiUrl: getApiUrl(element),
  apiKey: getAttributeValue(element, "data-api-key"),
  textColor: getAttributeValue(element, "data-color-text") || "#333",
  mapPinFillColor:
    getAttributeValue(element, "data-map-pin-fill-color") || "#257eca",
  mapPinFillColorWinter:
    getAttributeValue(element, "data-map-pin-fill-color") || "#50727C",
  mapPinStrokeColor:
    getAttributeValue(element, "data-map-pin-stroke-color") || "#2d5069",
  mapPinFillColorActive:
    getAttributeValue(element, "data-map-pin-fill-color-active") || "#fff",
  mapPinStrokeColorActive:
    getAttributeValue(element, "data-map-pin-stroke-color-active") || "#50727C",
  mapPinFillColorOpen:
    getAttributeValue(element, "data-map-pin-fill-color-active") || "#64B968",
  mapPinStrokeColorOpen:
    getAttributeValue(element, "data-map-pin-fill-color") || "#64B968",
  mapAbove: parseInt(getAttributeValue(element, "data-map-above")) || 0,
  mapBelow: parseInt(getAttributeValue(element, "data-map-below")) || 0,
  difficultyColors: difficultyColors(element)
});

export const getProps = (element, prefix = "eopoi-app") => {
  const data = element.querySelector(`.${prefix}-initial-data`);
  const settings = element.querySelector(`.${prefix}-settings`);
  const categories = element.querySelector(`.${prefix}-categories`);
  const features = element.querySelector(`.${prefix}-features`);
  return {
    id: getAttributeValue(element, "id"),
    initialData: parseJsonSave(data?.innerHTML),
    initialSettings: parseJsonSave(settings?.innerHTML),
    initiatNextPage: getAttributeValue(element, "data-next-page") || -1,
    poiId:
      getAttributeValue(element, "data-poi") ||
      getAttributeValue(element, "data-event"),
    initialCategories: parseJsonSave(categories?.innerHTML),
    initialFeatures: parseJsonSave(features?.innerHTML),
    targetUri: getAttributeValue(element, "data-target-uri"),
    listUri: getAttributeValue(element, "data-list-uri"),
    apiUrl: getApiUrl(element),
    apiKey: getAttributeValue(element, "data-api-key"),
    locale: getAttributeValue(element, "data-locale") || "de-de",
    limit: parseInt(getAttributeValue(element, "data-limit")) ?? 3,
    location: getAttributeValue(element, "data-location"),
    hideFilter: parseInt(getAttributeValue(element, "data-hide-filter")) ?? 0,
    featureIds: getAttributeValue(element, "data-features")?.split(",") || [],
    categoryIds:
      getAttributeValue(element, "data-categories")?.split(",") || [],
    highlight: parseInt(getAttributeValue(element, "data-highlight")) === 1,
    sorting: getAttributeValue(element, "data-sorting"),
    textColor: getAttributeValue(element, "data-color-text") || "#333",
    difficultyColors: difficultyColors(element)
  };
};

const parseJsonSave = (jsonString, logError) => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    if (logError) console.error("can't parse: " + jsonString, error);
    return undefined;
  }
};

export const insertCss = () => {
  document
    .getElementsByTagName("head")[0]
    .insertAdjacentHTML(
      "beforeend",
      `<link href="https://cdn.eberl-online.cloud/ajax/libs/MaterialDesign-Webfont/5.9.55/css/materialdesignicons.min.css" media="all" rel="stylesheet" type="text/css">`
    );
};

export const createEntry = (view, elementsClassName) => {
  Vue.config.productionTip = false;
  Vue.use(Vuetify);

  const elements = [...document.getElementsByClassName(elementsClassName)];
  elements.forEach(element => {
    const vuetify = new Vuetify({
      icons,
      theme: getTheme(element),
      lang: {
        locales: { de, en },
        current: getAttributeValue(element, "data-locale") || "de-de"
      }
    });

    const namespace = getAttributeValue(element, "data-namespace") || "eopoi";
    const store = appStore(namespace, getDefaultStoreValues(element));

    new Vue({
      vuetify,
      store,
      render: function(h) {
        return h(view, {
          props: getProps(element, elementsClassName)
        });
      }
    }).$mount(element);
  });
};
