<script>
export default {
  name: "PictureMixin",
  methods: {
    pictureFullCopyRight(picture) {
      const copyRight = [];

      if (picture && picture.license) {
        if (picture.license.type) copyRight.push(picture.license.type);
        if (picture.license.author) copyRight.push(picture.license.author);
        if (picture.license.source) copyRight.push(picture.license.source);
      }

      return copyRight.join(" – ");
    },
    pictureLicense(picture) {
      if (!picture.license) {
        return "";
      }
      if (!(picture.license.type && picture.license.url)) {
        return "";
      }
      return `<a href="${picture.license.url}">${picture.license.type}</a>`;
    },
    getPictureSeason() {
      if (this.apiKeyObj?.pictureSeason?.length) {
        if (this.apiKeyObj.pictureSeason === "principalSeason") {
          return this.$store.state.globalSeason;
        }
        return this.apiKeyObj.pictureSeason;
      }
      if (this.apiKeyObj?.season?.length) {
        if (this.apiKeyObj.season === "principalSeason") {
          return this.$store.state.globalSeason;
        }
        return this.apiKeyObj.season;
      }
      return this.$store.state.isWinter ? "winter" : "summer";
    },
    filterSeasonImages(pictures) {
      if (!pictures || !pictures.length) return [];

      const currentSeason = this.getPictureSeason();

      if (!currentSeason?.length || currentSeason === "all") {
        return pictures.filter(picture => picture.print !== "printonly");
      }
      // filter pictures by not type and web
      const filteredPictures = pictures.filter(
        picture =>
          (picture.type === currentSeason || picture.type === "always") &&
          picture.print !== "printonly"
      );
      if (filteredPictures?.length) return filteredPictures;
      else {
        return pictures.filter(picture => picture.print !== "printonly");
      }
    },
    camAndSeasonImages(poi) {
      const pictures = poi ? this.filterSeasonImages(poi.pictures) : [];
      let camImages = poi?.webcamLinks || [];
      camImages = camImages.filter(ci => {
        return ci.link?.length > 0;
      });
      if (
        this.poi?.showWebcamsFirst ||
        this.apiKeyObj?.displayOnlyWithWebcams
      ) {
        return camImages.concat(pictures);
      }
      return pictures.concat(camImages);
    },
    timeStampUrl(url) {
      //add get-string. changes every minute to avoid caching
      url += url?.includes("?") ? "&eo-minute=" : "?eo-minute=";
      url += window.encodeURIComponent(Math.floor(new Date() / 1000 / 60));
      return url;
    },
    isImageFilename(str) {
      const suffix = str
        .trim()
        .split(".")
        .pop();
      if (!suffix) {
        return false;
      }
      return ["jpg", "jpeg", "png", "gif", "webp"].includes(
        suffix.toLowerCase()
      );
    }
  },
  computed: {
    apiKeyObj() {
      return this.settings?.apiKey ?? {};
    }
  }
};
</script>
