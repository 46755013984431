// helper to get data from session storage
export function getFromStorage(key) {
  const data = sessionStorage.getItem(key);
  try {
    return data ? JSON.parse(data) : false;
  } catch (e) {
    // if no JSON - do not fail
    return false;
  }
}

// helper to write boolean data to session storage
export function saveToStorage(key, value) {
  sessionStorage.setItem(key, JSON.stringify(value));
}
