var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],ref:"mapContainer",staticClass:"eopoi-app-map"},[(_vm.bounds)?_c('l-map',{ref:"myMap",style:({
      height: _vm.mapHeight + 'px',
      width: !_vm.isMobile ? _vm.calculatedMapWidth : '100%',
      maxWidth: '100%'
    }),attrs:{"minZoom":5,"maxZoom":18,"maxBounds":[
      [43, 3],
      [57, 20]
    ],"bounds":_vm.bounds},on:{"ready":_vm.mapLoaded}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution}}),_vm._l((_vm.filterGeo()),function(poi){return _c('MapIcon',{key:poi._id,attrs:{"active":_vm.rolloverLocationId === poi._id,"lat-lng":{ lat: poi.geo.lat, lng: poi.geo.lng },"poi":poi,"isParkingLot":_vm.checkStep('parkingLot', poi),"stroke":_vm.getColorForParkingLot(poi),"fill":poi.state && poi.state.state !== 'closed'
          ? _vm.getColorForParkingLot(poi)
          : 'transparent'},on:{"mouseenter":function($event){return _vm.onMouseEnter(poi)},"mouseleave":_vm.onMouseLeave}},[_c('l-popup',[_c('p',{staticClass:"eopoi-app-map-marker-head"},[_vm._v(_vm._s(poi.title))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.formatAddress(poi))}}),_c('a',{attrs:{"href":_vm.href(poi)}},[_vm._v("Details")])])],1)})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }