import "./FooterIconLegend.sass";

export default {
  props: ["settings", "listView"],
  computed: {
    isReport() {
      return this.settings?.apiKey?.isReport;
    },
    hideLegend() {
      return this.settings?.apiKey?.hideLegend;
    }
  }
};
