import PoiOpenClosedIcons from "@/components/PoiOpenClosedIcons/PoiOpenClosedIcons.vue";
import "./ListView.sass";
import { apiString } from "@/helpers/detailViewHelper";

export default {
  name: "list-view",
  components: {
    PoiOpenClosedIcons
  },
  props: {
    showMap: Boolean,
    pois: Array,
    currentSeason: String
  },
  computed: {
    apiString
  },
  methods: {
    getHrefByPoi(poi) {
      return this.$store.state.targetUri.replace(
        /xxxSLUGxxx/,
        (poi.slug || poi._id) + this.apiString
      );
    }
  }
};
