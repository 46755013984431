<script>
export default {
  name: "ColorsMixin",
  computed: {
    textColor() {
      return this.$store.state.textColor;
    },
    accentColor() {
      return this.$vuetify.theme.currentTheme.accent;
    }
  }
};
</script>
