import Card from "@/components/Card/Card.vue";
import ListFilterDialog from "@/components/ListFilterDialog/ListFilterDialog.vue";
import ListFilter from "@/components/ListFilter/ListFilter.vue";
import Map from "@/components/Map/Map.vue";
import ListView from "@/components/ListView/ListView.vue";
import FooterIconLegend from "@/components/FooterIconLegend/FooterIconLegend.vue";
import { getPois, getRegionSubPoisOpen } from "@/helpers/apiHelper";
import "@/App.sass";
import "./List.sass";
import flatten from "lodash/flatten";
import uniqBy from "lodash/uniqBy";
import {
  getSettings,
  fixFeaturesAndCategoriesForQuery
} from "@/helpers/commonHelper";
import { getSkiRegionInfo } from "@/helpers/detailViewHelper";

const LAZY_LOAD_PAGE_SIZE = 15;
const LAZY_LOAD_LIST_PAGE_SIZE = 50;

Array.prototype.asyncForEach = async function(callback) {
  for (let index = 0; index < this.length; index++) {
    await callback(this[index], index, this);
  }
};
export default {
  name: "list",
  components: {
    Card,
    ListFilterDialog,
    ListFilter,
    Map,
    ListView,
    FooterIconLegend
  },
  props: {
    id: {
      type: String,
      default: "eopoi-list"
    },
    initialData: Array,
    initialSettings: Object,
    initialNextPage: {
      type: Number,
      default: -1
    },
    apiKey: String,
    hideFilter: Number,
    categoryIds: Array,
    featureIds: Array
  },
  data: () => ({
    isCreating: true, // initial creation process
    isLoading: true, // initial load?
    pois: [], // list of pois,
    mappedPois: {},
    mappedPoisRequested: false,
    loadingCompleted: false,
    loadingInProgress: false,
    shouldReload: false,
    settings: {}, // loaded settings
    totalCount: 0, // total results
    countByCategory: {}, // facet count by category id
    countByFeature: {}, // facet count by feature id
    nextPage: 0, // next page
    error: "",
    widgetWidth: 0, // width of complete element
    leftColWidth: 0, // width of left column
    rightColWidth: 0, // width of right column
    // view states
    showFilterDialog: false,
    showNotepad: false,
    showFilter: false,
    sortings: [
      /*{
        text: "Sortierung",
        value: "random"
      }*/
    ],
    currentSorting: "sortTitle:asc",
    showTypeFilter: {
      showTourFilter: true,
      showLakeFilter: true,
      showRailwayFilter: true,
      showHutsFilter: true,
      showFreeTimeFilter: true,
      showParkingLotFilter: true
    },
    showTourFilter: true,
    showLakeFilter: true,
    showRailwayFilter: true,
    showHutsFilter: true,
    showFreeTimeFilter: true,
    showParkingLotFilter: true,
    poisProcessing: false,
    selectedViewType: "cardView",
    currentPage: 0,
    resizeObserver: null
  }),
  async mounted() {
    this.$store.commit("SET_SETTINGS", { apiKey: this.apiKey });
    this.checkForMappingFieldParam();
    await this.$store.dispatch("getMappingFields");
    await Promise.all([this.getDataFromStore(), this.getSettings()]);
    this.calculateWidgetOffsets();
    if (this.apiKeyObj?.outputFormat)
      this.selectedViewType = this.apiKeyObj?.outputFormat;
    setTimeout(() => {
      const mapSwitchValue =
        this.$store.state.mapSwitch === "open" ||
        this.$store.state.mapSwitch === "closed";
      if (mapSwitchValue) {
        this.toggleMap(this.$store.state.mapSwitch === "open");
      } else {
        this.toggleMap(this.settings.apiKey.showMap);
      }
    }, 150);
    this.loadData();

    if (!this.resizeObserver) {
      this.resizeObserver = new ResizeObserver(this.forceTriggerMapOnScroll);
    }
    this.resizeObserver.observe(this.$refs.theLeftCol);
  },
  unmounted() {
    this.resizeObserver?.disconnect();
  },
  methods: {
    forceTriggerMapOnScroll() {
      if (this.isMobile) {
        return;
      }
      window.scrollTo(window.scrollX, window.scrollY + 1);
      window.scrollTo(window.scrollX, window.scrollY - 1);
    },
    checkForMappingFieldParam() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const mappingField = urlParams.get("mappingField");
      if (mappingField) {
        this.$store.commit("SET_SELECTED_MAPPING_FIELD", mappingField);
      }
    },
    toggleLoadingProcess() {
      this.pois = [];
      this.isLoading = true;
    },
    lazyLoad() {
      let currentItemCount = (this.currentPage - 1) * LAZY_LOAD_PAGE_SIZE;
      if (this.selectedViewType === "listView") {
        currentItemCount = (this.currentPage - 1) * LAZY_LOAD_LIST_PAGE_SIZE;
      }
      if (
        currentItemCount <= this.pois.length ||
        currentItemCount <= this.totalCount
      ) {
        this.loadingInProgress = true;
        this.loadData(this.currentPage, false);
      } else {
        this.loadingInProgress = false;
      }
    },
    loadData(page = -1, resetData = true) {
      if (!this.poisProcessing) {
        this.poisProcessing = true;
        this.$store.commit("SET_LIST_LOADING", true);
        this.currentPage += 1;
        // Don't load with await to prevent render-blocking
        const filterFeatures = this.featureIds?.length ? this.featureIds : [];
        const filterCategories = this.categoryIds?.length
          ? this.categoryIds
          : [];

        this.$store.commit(
          "SET_SELECTED_FEATURES",
          this.selectedFeatures.concat(filterFeatures)
        );
        this.$store.commit(
          "SET_SELECTED_CATEGORIES",
          this.selectedCategories.concat(filterCategories)
        );
        getPois(
          this.apiUrl,
          this.apiKey,
          this.createQuery(page),
          this.openFilter
        )
          .then(async data => {
            this.evaluateMap();
            this.showTypeFilter = {
              showTourFilter: !!data.toursCount,
              showLakeFilter: !!data.lakeCount,
              showRailwayFilter: !!data.railwayCount,
              showHutsFilter: !!data.hutsCount,
              showFreeTimeFilter: !!data.freeTimeCount,
              showParkingLotFilter: !!data.parkingLotCount
            };
            await this.setPoisDataFromSource(data, resetData);
            if (!this.mappedPoisRequested) {
              this.setPOIsForMappingFields();
            }
            this.isLoading = false;
            this.isCreating = false;
            this.poisProcessing = false;
            this.$store.commit("SET_LIST_LOADING", false);
            this.observeItemsForLazyload();
          })
          .catch(e => {
            this.poisProcessing = false;
            this.$store.commit("SET_LIST_LOADING", false);
            this.error = e;
          })
          .finally(() => {
            this.loadingInProgress = false;
          });
      }
    },
    observeItemsForLazyload() {
      let namespace = "[namespace=" + this.$store.state.namespace + "] ";
      if (!document.querySelector(namespace)) {
        namespace = "";
      }
      let itemsSelector = ".eopoi-app-card";
      if (this.selectedViewType !== "cardView")
        itemsSelector = ".poi-list-item";
      // trigger on intersection with 6th last item or last item
      this.observeLoadingTrigger(namespace + itemsSelector, this.lazyLoad);
      this.observeLoadingTrigger(namespace + ".loadTrigger", this.lazyLoad);
      this.observeLoadingTrigger(namespace + ".loadTriggerList", this.lazyLoad);
    },
    observeLoadingTrigger(itemsSelector, callback) {
      const items = [...document.querySelectorAll(itemsSelector)];
      let triggerItem = items[items.length - 1];
      if (this.selectedViewType === "listView") {
        triggerItem =
          items[items.length - 60] ||
          items[items.length - 40] ||
          items[items.length - 20] ||
          items[items.length - 1];
      }
      const itemObserver = new IntersectionObserver(function(entries) {
        entries.forEach(function(entry) {
          if (entry.isIntersecting) {
            //entry.unobserve();
            itemObserver.disconnect();
            callback(entry);
          }
        }, {});
      });
      if (!triggerItem) {
        console.error("can't find loading-trigger. selector:" + itemsSelector);
        return;
      }
      itemObserver.observe(triggerItem);
    },
    setPOIsForMappingFields() {
      this.loadingCompleted = false;
      this.mappedPoisRequested = true;
      this.mappedPois = {};
      const filterFeatures = this.featureIds?.length ? this.featureIds : [];
      const filterCategories = this.categoryIds?.length ? this.categoryIds : [];
      this.$store.commit(
        "SET_SELECTED_FEATURES",
        this.selectedFeatures.concat(filterFeatures)
      );
      this.$store.commit(
        "SET_SELECTED_CATEGORIES",
        this.selectedCategories.concat(filterCategories)
      );
      const query = this.createQuery(undefined);
      const promises = [
        getPois(this.apiUrl, this.apiKey, {
          ...query,
          selectedMappingField: this.$store.state.selectedMappingField
        })
      ];
      // Do not load with await to prevent renderblocking
      Promise.all(promises).then(result => {
        result.forEach(r => {
          this.mappedPois[r.mappingField] = r;
        });
        this.loadingCompleted = true;
      });
    },
    async setPoisDataFromSource(data, resetData) {
      if (resetData) {
        this.countByFeature = {};
        this.countByCategory = {};
      }
      let poiArr = [];
      await data.data.asyncForEach(async p => {
        if (p.category === "60b49f7b9d71c30012efbe57") poiArr.push(p._id);
      });
      let skiRegionInfo = [];
      if (poiArr.length) skiRegionInfo = await this.getSkiRegionInfo(poiArr);
      data?.data?.forEach(p => {
        skiRegionInfo.forEach(regionInfo => {
          if (p?.regions?.includes(regionInfo?.regionId))
            p.skiRegionInfo = regionInfo;
        });
      });
      this.pois = resetData
        ? data.data
        : uniqBy(this.pois.concat(data.data), "_id");
      this.totalCount = data.total;
      this.countByFeature = this.mergeObjects(
        this.countByFeature,
        data.countByFeature || {}
      );
      this.countByCategory = this.mergeObjects(
        this.countByCategory,
        data.countByCategory || {}
      );

      this.countByFeature = data.countByFeature || {};
      this.countByCategory = data.countByCategory || {};
    },
    mergeObjects(initialObj, objectToMerge) {
      Object.keys(objectToMerge).forEach(key => {
        if (!initialObj[key]) initialObj[key] = objectToMerge[key];
        else initialObj[key] += objectToMerge[key];
      });
      return initialObj;
    },
    async getSettingsFromApi() {
      await getSettings.bind(this).call();
    },
    labelFromId(id) {
      return this.$store.state.labelsById[id] || "???";
    },
    resizeMap() {
      if (this.$refs.theWidget)
        this.widgetWidth = this.$refs.theWidget.offsetWidth;
      if (this.$refs.theLeftCol)
        this.leftColWidth = this.$refs.theLeftCol.offsetWidth;
      if (this.$refs.theRightCol)
        this.rightColWidth = this.$refs.theRightCol.offsetWidth - 8; // Padding = 2*4px
    },
    toggleMap(value, timeout = 150) {
      this.showMap = value;
      // trigger resize
      const that = this;
      setTimeout(function() {
        that.resizeMap();
      }, timeout);
    },
    async removeSearchTerm() {
      this.toggleLoadingProcess();
      this.$store.commit("SET_SEARCH", undefined);
      await this.applyFilter();
    },
    async removeSelectedCategory(categoryId) {
      const selectedCategories = this.$store.state.selectedCategories;
      const pos = selectedCategories.indexOf(categoryId);
      if (pos > -1) {
        selectedCategories.splice(pos, 1);
        await this.setSelectedCateogries(selectedCategories);
      }
    },
    async removeSelectedFeature(featureId) {
      const selectedFeatures = this.$store.state.selectedFeatures;
      const pos = selectedFeatures.indexOf(featureId);
      if (pos > -1) {
        selectedFeatures.splice(pos, 1);
        await this.setSelectedFeatures(selectedFeatures);
      }
    },
    toggleFilterFavorites() {
      this.$store.commit("TOGGLE_FAVORITES_FILTER");
    },
    async changeSorting(newValue = undefined) {
      if (newValue) this.currentSorting = newValue;

      const parts = this.currentSorting.split(":");
      if (parts.length === 2) {
        this.$store.commit("SET_SORTING", {
          sort: parts[0],
          direction: parts[1]
        });
        await this.applyFilter();
      }
    },
    async setSelectedMappingField(selectedMappingField) {
      this.toggleLoadingProcess();
      this.$store.commit("SET_LIST_LOADING", true);
      this.$store.commit("SET_TASK_LOADING", {
        taskName: "filterCategoriesChange",
        isLoading: true
      });
      if (this.selectedCategories?.length) {
        this.$store.commit("SET_SELECTED_CATEGORIES", []);
      }
      if (this.selectedFeatures?.length) {
        this.$store.commit("SET_SELECTED_FEATURES", []);
      }
      this.selectedMappingField = selectedMappingField;
    },
    async setSelectedCateogries(selectedCategories) {
      this.toggleLoadingProcess();
      this.$store.commit("SET_SELECTED_CATEGORIES", selectedCategories);
      await this.applyFilter();
    },
    async setSelectedFeatures(selectedFeatures) {
      this.toggleLoadingProcess();
      this.$store.commit("SET_SELECTED_FEATURES", selectedFeatures);
      await this.applyFilter();
    },
    setSelectedViewType(value) {
      console.info("showMap", { blub: this.showMap });
      const shouldShowMap = this.showMap;
      this.showMap = false;
      this.selectedViewType = value;
      setTimeout(() => {
        this.toggleMap(shouldShowMap);
      }, 150);
      this.observeItemsForLazyload();
    },
    setFilterDialog(value) {
      this.showFilterDialog = value;
    },
    getMappedPois(mappingField) {
      return this.loadingCompleted ? this.mappedPois[mappingField] : this.pois;
    },
    calculateWidgetOffsets() {
      if (this.$refs.theWidget)
        this.widgetWidth = this.$refs.theWidget.offsetWidth;
      if (this.$refs.theLeftCol)
        this.leftColWidth = this.$refs.theLeftCol.offsetWidth;
      if (this.$refs.theRightCol)
        this.rightColWidth = this.$refs.theRightCol.offsetWidth - 8; // Padding = 2*4px
    },
    evaluateMap() {
      //ignore if already defined
      if (this.$store.state.mapSwitch) {
        return;
      }
      // map-toggle open or closed by default
      if (this.widgetWidth >= 600) {
        this.showMap = !!this.settings.apiKey.showMap;
        if (this.showMap) {
          this.toggleMap(this.showMap);
        }
      }
    },
    async getDefaultSortingAndFiltering() {
      const { sort, direction } = this.$store.state;
      if (sort) this.currentSorting = `${sort}:${direction}`;

      this.sortings = [
        {
          text: "Sortierung",
          value: "random"
        }
      ];
      this.showNotepad = this.settings.apiKey.showNotepad;
      this.showFilter = this.hideFilter
        ? !!this.hideFilter
        : this.settings.apiKey.showFilter;
      if (this.settings.apiKey.displayCurrentlyOpen) {
        this.sortings = this.sortings.concat([
          {
            text: "Geöffnet",
            value: "-x-open-close:asc"
          },
          {
            text: "Geöffnet",
            value: "-x-open-close:desc"
          }
        ]);
      }

      this.sortings = this.sortings.concat([
        {
          text: "Titel",
          value: "sortTitle:asc"
        },
        {
          text: "Titel",
          value: "sortTitle:desc"
        }
      ]);
    },
    async getDataFromStore() {
      await this.$store.dispatch("getCategories");
      await this.$store.dispatch("getFeatures");

      await this.$store.dispatch("getCategoryGroups");
      await this.$store.dispatch("getCategoryTypes");
      await this.$store.dispatch("getCities");
    },
    async getSettings() {
      if (this.initialSettings) {
        this.settings = this.initialSettings;

        // initial setting of search parameters - from external search
        const fromSearch = !!this.settings.from_search;
        if (fromSearch) {
          // reset certain searches that have not been defined in search form
          this.$store.commit("SET_SELECTED_FEATURES", []);
          if (this.$store.state.filterFavorites)
            this.$store.commit("TOGGLE_FAVORITES_FILTER");
        }

        if (this.settings.s) {
          this.$store.commit("SET_SEARCH", this.settings.s);
        } else if (fromSearch) this.$store.commit("SET_SEARCH", "");

        if (this.settings.categories) {
          this.$store.commit(
            "SET_SELECTED_CATEGORIES",
            this.settings.categories
          );
        } else if (fromSearch)
          this.$store.commit("SET_SELECTED_CATEGORIES", []);
      } else {
        await this.getSettingsFromApi();
      }
      // store default values in store
      this.$store.commit("SET_SETTINGS", {
        sort: this.settings.apiKey?.defaultSorting,
        direction: this.settings.apiKey?.defaultDirection,
        isWinter: this.currentSeason === "winter"
      });
      await this.getDefaultSortingAndFiltering();
    },
    createQuery(page = -1) {
      // offset set?
      if (this.settings?.apiKey?.startOffset > 0) {
        page = 0;
      }
      const limit =
        this.selectedViewType === "listView"
          ? LAZY_LOAD_LIST_PAGE_SIZE
          : LAZY_LOAD_PAGE_SIZE;

      // define query
      const query = {
        extended: true,
        page: page <= 0 ? this.settings.apiKey.startPage : page,
        limit,
        offset: this.settings.apiKey.startOffset,
        selectedMappingField: this.selectedMappingField,
        highlight_to_top: this.settings.apiKey.showHighlightToTop,
        sort:
          this.$store.state.sort ||
          this.settings.apiKey?.defaultSorting ||
          "sortTitle",
        direction:
          this.$store.state.direction ||
          this.settings.apiKey?.defaultDirection ||
          "asc"
      };

      if (query.sort === "random") {
        query.excludePois = this.pois.map(p => p._id).join(",");
      }

      if (this.difficulty) query.difficulty = this.difficulty;

      // filter by favorites?
      if (this.$store.state.filterFavorites) {
        if (this.$store.state.favorites) {
          query._id = this.$store.state.favorites;
        }
      } else {
        // general filters - only if no favorites are filtered
        query.active = this.settings.apiKey.showReleased;
        query.hidden = 0;
        query.highlight = this.settings.apiKey.showHighlight;

        // normal search
        query.feature_ids = this.$store.state.selectedFeatures
          ? this.$store.state.selectedFeatures.slice()
          : []; // clone array

        query.category_ids = this.$store.state.selectedCategories
          ? this.$store.state.selectedCategories.slice()
          : []; // clone arraa
        query.cities = this.$store.state.selectedCities?.length
          ? this.$store.state.selectedCities.slice()
          : this.settings.apiKey.cities ?? []; // clone array

        // search term
        if (this.$store.state.s) query.s = this.$store.state.s;

        // manually contain category ids, if needed
        fixFeaturesAndCategoriesForQuery(query, this.settings);
      }
      return query;
    },
    async applyFilter() {
      if (!this.isCreating) {
        if (this.loadingInProgress) this.shouldReload = true;
        else {
          this.currentPage = 0;
          await this.loadData(this.currentPage);
        }
      }
    },
    checkSeason(season) {
      return this.currentSeason === season;
    },
    getSeasonMappingFields() {
      const mappingFields = this.checkSeason("summer")
        ? this.$store.state.mappingFields.filter(field => field.summer)
        : this.$store.state.mappingFields.filter(field => field.winter);

      if (this.apiKeyObj?.includedMappingFields?.length) {
        return mappingFields.filter(mappingField =>
          this.apiKeyObj.includedMappingFields.includes(mappingField._id)
        );
      } else return mappingFields;
    },
    getSeasonalMappingField() {
      const summerMappingField = this.mappingFields.find(
        mField => mField.summer
      );
      const winterMappingField = this.mappingFields.find(
        mField => mField.winter
      );
      if (!summerMappingField?._id) return winterMappingField._id;
      if (!winterMappingField?._id) return summerMappingField._id;
      if (this.currentSeason === "winter") return winterMappingField._id;
      return summerMappingField._id;
    },
    getSkiRegionInfo,
    getRegionSubPoisOpen
  },
  computed: {
    mappingFields() {
      return this.$store.state.mappingFields;
    },
    cols() {
      if (this.leftColWidth > 1900) {
        return 2;
      }
      if (this.isMobile || this.leftColWidth <= 600) {
        return 12;
      }

      if (this.leftColWidth >= 1260) {
        return 3;
      }
      if (this.leftColWidth >= 960) {
        return 4;
      }
      return 6;
    },
    totalFavorites() {
      return this.$store.state.favorites.length;
    },
    selectedCategories() {
      return this.$store.state.selectedCategories?.filter(ct => ct.length > 1);
    },
    selectedFeatures() {
      return this.$store.state.selectedFeatures?.filter(ft => ft?.length > 1);
    },
    favoritesFiltered() {
      return this.$store.state.filterFavorites;
    },
    searchTerm() {
      return this.$store.state.s;
    },
    hideCopyright() {
      return !!this.settings.apiKey?.hideCopyright;
    },
    ownCopyright() {
      if (this.settings.apiKey?.ownCopyright !== "") {
        return this.settings.apiKey?.ownCopyright;
      } else return "Made with ♥ EO Heimat / OYA media";
    },
    ownCoprightUrl() {
      if (this.settings.apiKey?.ownCoprightUrl !== "") {
        return this.settings.apiKey?.ownCoprightUrl;
      } else return "https://www.oya-media.de";
    },
    isMobile() {
      return this.widgetWidth < 600; // => https://vuetifyjs.com/de-DE/customization/breakpoints/
    },
    currentSortingTitle() {
      if (this.currentSorting && this.sortings && this.sortings.length) {
        for (let i = 0; i < this.sortings.length; i++) {
          if (this.sortings[i].value === this.currentSorting)
            return this.sortings[i].text;
        }
      }
    },
    maxMapHeight() {
      if (!this.$refs.header) return window.innerHeight;
      return window.innerHeight - this.$refs.header.clientHeight;
    },
    selectedMappingField: {
      get() {
        if (!this.isReport) {
          return "";
        }
        const mappingField =
          this.$store.state.selectedMappingField ||
          this.getSeasonalMappingField();
        return mappingField;
      },
      async set(value) {
        this.$store.commit("SET_SELECTED_MAPPING_FIELD", value);
        await this.applyFilter();
      }
    },
    currentSeason() {
      if (!this.apiKeyObj?.season)
        return this.$store.state.isWinter ? "winter" : "summer";
      if (this.apiKeyObj.season === "principalSeason")
        return this.settings.currentSeason;
      return this.apiKeyObj.season;
    },
    openFilter() {
      return this.$store.state.openFilter;
    },
    difficulty() {
      return this.$store.state.difficulty;
    },
    isReport() {
      return this.settings?.apiKey?.isReport;
    },
    categoryGroups() {
      return this.$store.state.categoryGroups;
    },
    categoryTypes() {
      return this.$store.state.categoryTypes;
    },
    showDifficultyFilter() {
      return this.pois?.some(poi => !!poi?.state?.difficulty);
    },
    apiUrl() {
      return this.$store.state.apiUrl;
    },
    categories() {
      const categoryTypes = this.$store.state.categories;
      const uiConfig = this.settings.apiKey?.searchFilterUiElements;
      const whiteList = uiConfig?.includedCategories;
      const whiteListEmpty = !whiteList?.length;

      return flatten(
        categoryTypes.map(categoryType =>
          categoryType.categories.map(category => ({
            ...category,
            typeTitle: categoryType.title
          }))
        )
      ).filter(
        category =>
          !uiConfig?.excludedCategories?.includes(category._id) &&
          this.countByCategory[category._id] &&
          (whiteListEmpty ||
            uiConfig?.includedCategories?.includes(category._id))
      );
    },
    apiKeyObj() {
      return this.settings?.apiKey ?? {};
    },
    showMap: {
      get() {
        return this.$store.state.mapSwitch === "open";
      },
      set(value) {
        this.$store.commit("SET_MAP_SWITCH", value ? "open" : "closed");
      }
    }
  },
  watch: {
    async totalFavorites(newValue) {
      // load, if we are in the favorites view
      if (this.$store.state.filterFavorites) {
        // if newValue == 0 -> reset filter
        if (newValue === 0) {
          this.$store.commit("TOGGLE_FAVORITES_FILTER");
        } else {
          await this.applyFilter();
        }
      }
    },
    async loadingInProgress(value) {
      if (!value && this.shouldReload) {
        this.shouldReload = false;
        await this.applyFilter();
      }
    },
    showFilterDialog(value) {
      document.body.style.overflowY = value ? "hidden" : "inherit";
    },
    selectedMappingField() {
      this.$refs.listFilterDialog?.themeCategoriesToggle();
    }
  }
};
