import Fins from "@/assets/svg/Fins";
import Boot from "@/assets/svg/Boot";
import CableWay from "@/assets/svg/CableWay";
import CableWayPeople from "@/assets/svg/CableWayPeople";
import Hut from "@/assets/svg/Hut";
import HutAlt from "@/assets/svg/HutAlt";
import Kayaking from "@/assets/svg/Kayaking";
import MountainBiking from "@/assets/svg/MountainBiking";
import PinFavorite from "@/assets/svg/PinFavorite";
import Swimming from "@/assets/svg/Swimming";
import Hiking from "@/assets/svg/Hiking";
import Facilities from "@/assets/svg/Facilities";
import Bike from "@/assets/svg/Bike";
import ParkingLot from "@/assets/svg/ParkingLot";
import ParkingLotOutline from "@/assets/svg/ParkingLotOutline";
import Sled from "@/assets/svg/Sled";
import Sledding from "@/assets/svg/Sledding";
import Slope from "@/assets/svg/Slope";
import CrossCountrySkiing from "@/assets/svg/CrossCountrySkiing";
import SnowShoes from "@/assets/svg/SnowShoes";
import SkiingPeople from "@/assets/svg/SkiingPeople";
import IceSkate from "@/assets/svg/IceSkate";
import SnowShoeing from "@/assets/svg/SnowShoeing";

const svgIcons = {
  fins: { component: Fins },
  boot: { component: Boot },
  cableWay: { component: CableWay },
  cableWayPeople: { component: CableWayPeople },
  hut: { component: Hut },
  hutAlt: { component: HutAlt },
  kayaking: { component: Kayaking },
  mountainBiking: { component: MountainBiking },
  pinFavorite: { component: PinFavorite },
  swimming: { component: Swimming },
  hiking: { component: Hiking },
  facilities: { component: Facilities },
  bike: { component: Bike },
  parkingLot: { component: ParkingLot },
  parkingLotOutline: { component: ParkingLotOutline },
  sled: { component: Sled },
  sledding: { component: Sledding },
  slope: { component: Slope },
  crossCountrySkiing: { component: CrossCountrySkiing },
  snowShoes: { component: SnowShoes },
  skiingPeople: { component: SkiingPeople },
  iceSkate: { component: IceSkate },
  snowShoeing: { component: SnowShoeing }
};

export default svgIcons;
