import "./Attach.sass";
import uniqueId from "lodash/uniqueId";
export default {
  data: () => ({
    idTarget: "",
    selector: ""
  }),
  created() {
    this.idTarget = uniqueId("attach_select_");
    this.selector = "#" + this.idTarget;
  }
};
