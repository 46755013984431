// alternative to optional chaining (missing in vue2-templates)
// f.e. <div v-if="opChain('settings.apiKey.displayShortTitle')">{{ poi.shortTitle }}</div>
export function opChain(propertyChain) {
  const chain = propertyChain.split(".");
  let prop = this[chain.shift()];
  chain.forEach(c => {
    prop = prop[c];
  });
  return prop;
}
