const mainBlue = "#50727C";
const mainYellow = "#E4CC37";
const mainRed = "#AF3E4D";

export function checkOpeningHours(openingHours) {
  return (
    openingHours?.description?.description ||
    openingHours?.description?.seasonalDescription ||
    openingHours?.weekdays?.some(weekday => {
      return weekday?.times?.some(time => {
        return time?.to || time?.from;
      });
    })
  );
}

export function getSliceLength(description) {
  const regex = /\./gi;
  const indices = [];
  let result;
  while ((result = regex.exec(description))) {
    indices.push(result.index);
  }
  const index = indices.find(i => {
    return i > 400 && i < 600;
  });
  return index || 400;
}

export function moveToDates() {
  const openingHoursHeader = document.querySelector("#openingHoursHeader");
  this.cOpenPanel = 0;
  openingHoursHeader.scrollIntoView({ behavior: "smooth" });
}

export function onFavoritesClick(poi) {
  const id = poi._id;
  if (this.isFavorite) {
    this.$store.commit("UNSET_FAVORITES", id);
  } else {
    this.$store.commit("SET_FAVORITES", id);
  }
}

export function checkStep(step, poi, categoryGroups, categoryTypes) {
  const type = categoryTypes.find(ct => ct._id === poi?.categoryType);

  const group = categoryGroups.find(cg => {
    return !!cg?.categories?.find(cat => cat._id === poi.category);
  });

  return group?.poiSteps?.includes(step) || type?.poiSteps?.includes(step);
}

export function getColorForParkingLot(parkingLot) {
  if (parkingLot?.state?.state === "open") return mainBlue;
  else if (parkingLot?.state?.state === "openingHours" && parkingLot?.isOpen)
    return mainBlue;
  else if (parkingLot?.state?.state === "capacityExhausted") return mainRed;
  else if (parkingLot?.state?.state === "highWorkload") return mainYellow;
  else if (parkingLot?.state?.state === "closed") return mainBlue;
  else if (!parkingLot?.state?.state) return "white";
  else return mainBlue;
}

export function getGoogleRouteLinkForAddress(address) {
  if (address && address.address) {
    const parts = [address.address];
    ["zip", "city", "district", "country"].forEach(adr => {
      if (address[adr]) parts.push(address[adr]);
    });
    return encodeURIComponent(parts.join(" "));
  }
  return undefined;
}

export function apiString() {
  if (window.location.href.includes("apiKey="))
    return this.$store.state.apiKey
      ? "?apiKey=" + this.$store.state.apiKey
      : "";
  return "";
}

export function href(idOrSlug) {
  if (this.$store.state.targetUri) {
    // replace place holder strings with meaningful stuff
    return this.$store.state.targetUri.replace(
      /xxxSLUGxxx/,
      idOrSlug + this.apiString()
    );
  }
  return "";
}

export function navigate(idOrSlug) {
  const href = this.href(idOrSlug);
  window.location.assign(href);
  if (href.includes("#")) {
    window.location.reload();
  }
}

export async function getSkiRegionInfo(poi) {
  //is ski-region
  try {
    return await this.getRegionSubPoisOpen(
      this.$store.state.apiUrl,
      this.$store.state.apiKey,
      {
        mainPoi: poi
      }
    );
  } catch (err) {
    console.error(`can't get skiRegionInfo`, err);
  }
}

export function downloadGpx(poi) {
  let a = document.createElement("a");
  a.href = poi?.route?.gpx?.url;
  if (!a.href) return;
  //filename for download. might work for some browsers
  a.download = poi?.route?.gpx?.title || "route.gpx";
  a.click();
}
