import PoiPicture from "@/components/PoiPicture/PoiPicture.vue";
import PoiOpenClosed from "@/components/PoiOpenClosed/PoiOpenClosed.vue";
import ColorsMixin from "@/mixins/ColorsMixin.vue";
import {
  getLocation,
  getSeasonOrAnyDescription,
  mToKm
} from "@/helpers/commonHelper";
import "./Card.sass";
import { apiString, checkStep } from "@/helpers/detailViewHelper";
import { opChain } from "@/mixins/globalMethods";

export default {
  name: "Card",
  components: { PoiOpenClosed, PoiPicture },
  mixins: [ColorsMixin],
  props: {
    poi: {
      type: Object,
      required: true
    },
    cols: {
      type: Number,
      required: true
    },
    settings: {
      type: Object,
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    showNotepad: Boolean
  },
  data: () => ({
    isMouseOver: false,
    subTitleInsteadOfDescription: false,
    skiRegionInfo: {},
    showCopyrightInList: true
  }),
  created() {
    this.subTitleInsteadOfDescription =
      this.settings.apiKey.subTitleInsteadOfDescription ||
      this.subTitleInsteadOfDescription;
  },
  computed: {
    description() {
      // currently winter and also show winter season (both settings)
      const isWinter = this.currentSeason === "winter";
      return getSeasonOrAnyDescription(this.poi, isWinter, true, true);
    },
    location() {
      return getLocation(this.poi?.address, this.settings?.apiKey);
    },
    apiString,
    detailUrl() {
      if (this.$store.state.targetUri) {
        return this.$store.state.targetUri.replace(
          /xxxSLUGxxx/,
          (this.poi.slug || this.poi._id) + this.apiString
        );
      } else return "";
    },
    isFavorite() {
      return this.$store.state.favorites?.includes(this.poi._id);
    },
    isHovered() {
      return (
        this.$store.state.rolloverMapLocationId === this.poi._id?.toString()
      );
    },
    categoryGroups() {
      return this.$store.state.categoryGroups;
    },
    categoryTypes() {
      return this.$store.state.categoryTypes;
    },
    currentSeason() {
      if (!this.apiKeyObj?.season)
        return this.$store.state.isWinter ? "winter" : "summer";
      if (this.apiKeyObj.season === "principalSeason")
        return this.settings.currentSeason;
      return this.apiKeyObj.season;
    },
    category() {
      if (!this.poi.category) {
        return;
      }
      return this.$store.state.labelsById?.[this.poi.category];
    }
  },
  methods: {
    navigate(href) {
      window.location.assign(href);
    },
    setFavorite() {
      const id = this.poi._id;

      if (this.isFavorite) {
        this.$store.commit("UNSET_FAVORITES", id);
      } else {
        this.$store.commit("SET_FAVORITES", id);
      }
    },
    toggleHover(value) {
      this.isMouseOver = value;

      this.$store.commit(
        "SET_ROLLOVER_CARD_LOCATION_ID",
        value ? this.poi?._id : null
      );
    },
    checkStep(step, poi) {
      return checkStep(step, poi, this.categoryGroups, this.categoryTypes);
    },
    mToKm,
    opChain
  }
};
