import { getCategories, getCities, getFeatures } from "@/helpers/apiHelper";
import axios from "axios";

export default {
  async getCategories({ state, commit }) {
    if (!state.categories || state.categories.length === 0) {
      try {
        commit(
          "SET_CATEGORIES",
          await getCategories(state.apiUrl, state.apiKey)
        );
      } catch (e) {
        console.error(e);
      }
    }
    return state.categories;
  },
  async getFeatures({ state, commit }) {
    if (!state.featureGroups || state.featureGroups.length === 0) {
      try {
        commit("SET_FEATURES", await getFeatures(state.apiUrl, state.apiKey));
      } catch (e) {
        console.error(e);
      }
    }
    return state.featureGroups;
  },
  async getCities({ commit, state }) {
    if (!state.cities || state.cities.length === 0) {
      try {
        commit("SET_CITIES", await getCities(state.apiUrl, state.apiKey));
      } catch (e) {
        console.error(e);
      }
    }
    return state.cities;
  },
  async getCategoryGroups({ commit, state }) {
    const { apiKey, apiUrl } = state;
    const response = await axios.get(`${apiUrl}categoryGroup/`, {
      params: { apiKey }
    });
    const categoryGroups = response?.data ?? [];
    commit("SET_CATEGORY_GROUPS", categoryGroups);
  },
  async getCategoryTypes({ commit, state }) {
    const { apiKey, apiUrl } = state;

    const response = await axios.get(`${apiUrl}categoryType/`, {
      params: { apiKey }
    });
    const categoryTypes = response?.data ?? [];
    commit("SET_CATEGORY_TYPES", categoryTypes);
  },

  async getMappingFields({ commit, state }) {
    const { apiKey, apiUrl } = state;
    const response = await axios.get(`${apiUrl}config`, {
      params: {
        apiKey
      }
    });
    let apiKeyMappingFields =
      response?.data[0].apiKeys[0].includedMappingFields ?? [];
    let query = {
      apiKey
    };
    if (apiKeyMappingFields.length) query._id = { $in: apiKeyMappingFields };
    const res = await axios.get(`${apiUrl}mappingfields`, {
      params: {
        ...query
      }
    });
    let mappingFields = res?.data || [];
    let sortedMappingFields = [];
    if (mappingFields.length && apiKeyMappingFields.length) {
      sortedMappingFields = apiKeyMappingFields.map(
        apiKeyMField =>
          (apiKeyMField = mappingFields.find(
            mField => mField._id === apiKeyMField
          ))
      );
    } else sortedMappingFields = mappingFields;
    commit("SET_MAPPING_FIELDS", sortedMappingFields);
  },

  async getAllMappingFieldsForDetailView({ commit, state }) {
    const { apiKey, apiUrl } = state;
    let query = {
      apiKey
    };
    const res = await axios.get(`${apiUrl}mappingfields`, {
      params: {
        ...query
      }
    });
    const mappingFields = res?.data || [];
    commit("SET_ALL_MAPPING_FIELDS", mappingFields);
  },

  async getRegionPois({ commit, state }, payload) {
    const { apiKey, apiUrl } = state;
    const regionsResults = await axios.get(`${apiUrl}region`, {
      params: {
        apiKey,
        mainPoi: payload.poiId
      }
    });
    const pois = [];
    regionsResults?.data[0]?.pois?.forEach(poi => {
      pois.push(poi);
    });

    commit("SET_REGION_POIS", pois);
  },

  async getRelatedPois({ commit, state }, payload) {
    const { apiKey, apiUrl } = state;

    const response = await axios.get(`${apiUrl}poi/`, {
      params: {
        apiKey,
        "_id[$in]": payload.relatedPoiIds,
        $limit: 9999,
        hidden: 0
      }
    });
    const pois = response?.data.data ?? [];

    commit("SET_RELATED_POIS", pois);
  }
};
