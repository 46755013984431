import { saveToStorage } from "@/helpers/storageHelper";
import { uniq } from "lodash/array";

export default namespace => ({
  SET_SETTINGS(state, payload) {
    if (!state.sort && payload.sort) state.sort = payload.sort;
    if (!state.direction && payload.direction)
      state.direction = payload.direction;
    if (payload.isWinter) state.isWinter = payload.isWinter;
    if (payload.showWinterSeason)
      state.showWinterSeason = payload.showWinterSeason;
    if (payload.apiKey) state.apiKey = payload.apiKey;
  },
  SET_FAVORITES(state, id) {
    if (state.favorites.indexOf(id) === -1) {
      state.favorites.push(id);
      // persist to local storage
      saveToStorage("eopoi-favorites", state.favorites);
    }
  },
  UNSET_FAVORITES(state, id) {
    const pos = state.favorites.indexOf(id);
    if (pos !== -1) {
      state.favorites.splice(pos, 1);
      // persist to local storage
      saveToStorage("eopoi-favorites", state.favorites);
    }
  },
  SET_SELECTED_CATEGORIES(state, newCats) {
    state.selectedCategories = uniq(newCats) || undefined;
    // persist to local storage
    saveToStorage(namespace + "-categories", state.selectedCategories);
  },
  SET_SELECTED_FEATURES(state, features) {
    state.selectedFeatures = uniq(features);
    // persist to local storage
    saveToStorage(namespace + "-features", state.selectedFeatures);
  },
  SET_SELECTED_CITIES(state, cities) {
    state.selectedCities = cities;
    saveToStorage(namespace + "-cities", state.selectedCities);
  },
  TOGGLE_FAVORITES_FILTER(state) {
    state.filterFavorites = !state.filterFavorites;
    // persist to local storage
    saveToStorage(namespace + "-filter-favorites", state.filterFavorites);
  },
  SET_GLOBAL_SEASON(state, globalSeason) {
    state.globalSeason = globalSeason;
  },
  SET_CATEGORIES(state, categoryTypes) {
    state.categories = categoryTypes;

    if (categoryTypes) {
      categoryTypes.forEach(categoryType => {
        categoryType.categories?.forEach(category => {
          state.labelsById[category._id] = category.title;
        });
      });
    }
  },
  SET_FEATURES(state, featureGroups) {
    state.features = featureGroups;

    if (featureGroups) {
      featureGroups.forEach(featureGroup => {
        featureGroup.features.forEach(feature => {
          state.labelsById[feature._id] = feature.title;
        });
      });
    }
  },
  SET_CITIES(state, cities) {
    state.cities = cities;
  },
  SET_SORTING(state, payload) {
    state.sort = payload.sort;
    state.direction = payload.direction;

    saveToStorage(namespace + "-filter-sort", state.sort);
    saveToStorage(namespace + "-filter-direction", state.direction);
  },
  SET_SEARCH(state, search) {
    state.s = search;
    // persist to local storage
    saveToStorage(namespace + "-filter-search", state.s);
  },
  SET_ROLLOVER_CARD_LOCATION_ID(state, id) {
    state.rolloverCardLocationId = id;
  },
  SET_ROLLOVER_MAP_LOCATION_ID(state, id) {
    state.rolloverMapLocationId = id;
  },
  SET_CATEGORY_GROUPS(state, categoryGroups) {
    state.categoryGroups = categoryGroups;
  },
  SET_CATEGORY_TYPES(state, categoryTypes) {
    state.categoryTypes = categoryTypes;
  },
  SET_RELATED_POIS(state, relatedPois) {
    state.relatedPois = relatedPois;
  },
  SET_REGION_POIS(state, regionPois) {
    state.regionPois = regionPois;
  },
  SET_SELECTED_MAPPING_FIELD(state, mappingField) {
    state.selectedMappingField = mappingField;

    saveToStorage(`${namespace}-mapping-field`, state.selectedMappingField);
  },
  SET_OPEN_FILTER(state, openFilter) {
    state.openFilter = openFilter;

    saveToStorage(`${namespace}-openFilter`, state.openFilter);
  },
  SET_MAP_SWITCH(state, mapSwitch) {
    state.mapSwitch = mapSwitch;
    saveToStorage(`${namespace}-mapSwitch`, state.mapSwitch);
  },
  SET_MAPPING_FIELDS(state, mappingFields) {
    state.mappingFields = mappingFields;
    saveToStorage(`${namespace}-mappingFields`, state.mappingFields);
  },
  SET_ALL_MAPPING_FIELDS(state, mappingFields) {
    state.allMappingFields = mappingFields;
    saveToStorage(`${namespace}-allMappingFields`, state.allMappingFields);
  },
  SET_DIFFICULTY(state, difficulty) {
    state.difficulty = difficulty;

    saveToStorage(`${namespace}-difficulty`, state.difficulty);
  },
  SET_LIST_LOADING(state, listLoading) {
    state.listLoading = listLoading;
  },
  SET_TASK_LOADING(state, { taskName, isLoading }) {
    state.tasksLoading[taskName] = isLoading;
  }
});
